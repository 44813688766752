import React, { useState } from "react";
import AIS from "../../media/Demo/ais.png";
import Bath from "../../media/Demo/bath.png";
import WindFarmSearch from "../../media/Demo/disipline.png";
import Elevation from "../../media/Demo/elevation.png";
import ElevationR1 from "../../media/Demo/elevationResults1.png";
import ElevationR2 from "../../media/Demo/elevationResults2.png";
import Persistence from "../../media/Demo/persistence.png";
import PersistenceResults from "../../media/Demo/persistenceReport.png";
import Ports from "../../media/Demo/ports.png";
import PowerInfra from "../../media/Demo/powerInfra.png";
import Weather from "../../media/Demo/weatherResults.png";
import Wind from "../../media/Demo/windClimate.png";
import YieldR1 from "../../media/Demo/yieldResults1.png";
import YieldR2 from "../../media/Demo/yieldResults2.png";
import { ReactComponent as YieldIcon } from "../../media/Yield.svg";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faDotCircle } from "@fortawesome/free-solid-svg-icons";
import PopupVideo from "../modals/PopupVideo";

function ServicesHomeEight() {
  const settings = {
    // dots: true,
    // infinite: true,
    // slidesToShow: 1,
    // slidesToScroll: 1,
    // autoplay: false,
    // speed: 1500,
    // cssEase: "linear",
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [showVideo, setVideoValue] = useState({ state: false, src: null });
  const handleShowVideo = (e) => {
    e.preventDefault();
    setVideoValue({ state: false, src: null });
  };

  const handleFullScreenImage = (img) => {
    setVideoValue({ state: true, src: img });
  };

  return (
    <>
      <section
        className="appie-services-2-area appie-services-8-area pt-185 pb-55"
        id="service"
      >
        <div className="container">
          <div className="row align-items-end">
            <div>
              <div className="appie-section-title">
                <h3 className="appie-title">
                  The comprehensive digital project solution
                </h3>
                <p>
                  Vekta Digital is a secure cloud based platform designed by
                  industry experts to revolutionise your project collaboration,
                  data management and engineering workflows. Our comprehensive
                  tool empowers you with: <br></br>
                  <br></br>
                  <FontAwesomeIcon
                    style={{ width: "8px" }}
                    color="#00a0c6"
                    icon={faCircle}
                  />{" "}
                  A secure platform to develop your projects individually or as
                  part of a team. <br></br>
                  <br
                    style={{
                      display: "block",
                      margin: "8px 0",
                      content: `" "`,
                    }}
                  ></br>
                  <FontAwesomeIcon
                    style={{ width: "8px" }}
                    color="#00a0c6"
                    icon={faCircle}
                  />{" "}
                  Access to trusted data sets and GIS tools. <br></br>
                  <br
                    style={{
                      display: "block",
                      margin: "8px 0",
                      content: `" "`,
                    }}
                  ></br>
                  <FontAwesomeIcon
                    style={{ width: "8px" }}
                    color="#00a0c6"
                    icon={faCircle}
                  />{" "}
                  Tools that are continuously updated and developed by your
                  feedback. <br></br>
                  <br
                    style={{
                      display: "block",
                      margin: "8px 0",
                      content: `" "`,
                    }}
                  ></br>
                  <FontAwesomeIcon
                    style={{ width: "8px" }}
                    color="#00a0c6"
                    icon={faCircle}
                  />{" "}
                  The ability to preserve knowledge, automate processes and
                  retain skills within your project. <br></br>
                  <br
                    style={{
                      display: "block",
                      margin: "8px 0",
                      content: `" "`,
                    }}
                  ></br>
                  {/* <FontAwesomeIcon
                    style={{ width: "8px" }}
                    color="#00a0c6"
                    icon={faCircle}
                  />{" "}
                  Uses the secure Google Cloud eco system. <br></br>
                  <br
                    style={{
                      display: "block",
                      margin: "8px 0",
                      content: `" "`,
                    }}
                  ></br> */}
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div style={{ margin: "16px 0" }} className="col-lg-6">
              <div
                className="
                    appie-single-service-2 appie-single-service-about
                    mt-30
                    wow
                    animated
                    fadeInUp
                  "
                data-wow-duration="2000ms"
                data-wow-delay="200ms"
              >
                <div className="icon">
                  <i class="fa-solid fa-earth-europe"></i>
                </div>
                <h4 className="title">Global Datasets</h4>
                <p>
                  Find all the data you could ever need in one place and just a
                  simple click away. Fully understand an area of interest by
                  displaying, uploading custom or querying layers.
                </p>

                <ul style={{ fontSize: "16px" }}>
                  <li>
                    <FontAwesomeIcon
                      style={{ width: "8px" }}
                      color="#00a0c6"
                      icon={faCircle}
                    />{" "}
                    <strong>Power Infrastructure</strong> – Collection of power
                    plants, power lines and generator points.
                  </li>
                  <li>
                    <FontAwesomeIcon
                      style={{ width: "8px" }}
                      color="#00a0c6"
                      icon={faCircle}
                    />{" "}
                    <strong>Bathymetry</strong> – Global bathymetry at 450m.
                  </li>
                  <li>
                    <FontAwesomeIcon
                      style={{ width: "8px" }}
                      color="#00a0c6"
                      icon={faCircle}
                    />{" "}
                    <strong>Wind Climate</strong> – 200m, 150m, 100m, 50m and
                    10m heights available to view.
                  </li>
                </ul>
              </div>
            </div>
            <div style={{ margin: "16px 0" }} className="col-lg-6">
              <div className="slider-container">
                <Slider {...settings}>
                  <div onClick={() => handleFullScreenImage(PowerInfra)}>
                    <label>Power Infrastructure Layers</label>
                    <img src={PowerInfra} />
                  </div>
                  <div onClick={() => handleFullScreenImage(Bath)}>
                    <label>Global Bathymetry</label>
                    <img src={Bath} />
                  </div>
                  <div onClick={() => handleFullScreenImage(Wind)}>
                    <label>Global Wind Climate</label>
                    <img src={Wind} />
                  </div>
                </Slider>
              </div>
            </div>

            <div style={{ margin: "16px 0" }} className="col-lg-6">
              <div className="slider-container">
                <Slider {...settings}>
                  <div onClick={() => handleFullScreenImage(YieldR1)}>
                    <label>Yield Results</label>
                    <img src={YieldR1} />
                  </div>
                  <div onClick={() => handleFullScreenImage(YieldR2)}>
                    <label>Yield Results</label>
                    <img src={YieldR2} />
                  </div>
                </Slider>
              </div>
            </div>
            <div style={{ margin: "16px 0" }} className="col-lg-6">
              <div
                className="
                    appie-single-service-2 appie-single-service-about
                    item-2
                    mt-30
                    wow
                    animated
                    fadeInUp
                  "
                data-wow-duration="2000ms"
                data-wow-delay="200ms"
              >
                <div className="icon">
                  <i>
                    <YieldIcon width={30} fill={"#db0f30"} />
                  </i>
                </div>
                <h4 className="title">Yield Calculator</h4>
                <p>
                  Estimate estimate site yield, and calculate Annual Energy
                  Production, Wake Effect, and Average Capacity Factor within
                  seconds. This powerful tool helps you make informed decisions,
                  monitor turbine health, and maximize your project’s efficiency
                  and profitability.
                </p>

                <ul style={{ fontSize: "16px" }}>
                  <li>
                    <FontAwesomeIcon
                      style={{ width: "8px" }}
                      color="#00a0c6"
                      icon={faCircle}
                    />{" "}
                    <strong>Advanced Site Builder</strong> – Choose from
                    existing sites or upload your own sites.
                  </li>
                  <li>
                    <FontAwesomeIcon
                      style={{ width: "8px" }}
                      color="#00a0c6"
                      icon={faCircle}
                    />{" "}
                    <strong>Instant Calculations</strong> – Get precise data on
                    energy production and capacity factors swiftly.
                  </li>
                  <li>
                    <FontAwesomeIcon
                      style={{ width: "8px" }}
                      color="#00a0c6"
                      icon={faCircle}
                    />{" "}
                    <strong>Downloadable Results</strong> – Seamlessly integrate
                    insights into your workflow with a handy downloadable .xlsx
                    file.
                  </li>
                </ul>
              </div>
            </div>

            <div style={{ margin: "16px 0" }} className="col-lg-6">
              <div
                className="
                    appie-single-service-2 appie-single-service-about
                    item-3
                    mt-30
                    wow
                    animated
                    fadeInUp
                  "
                data-wow-duration="2000ms"
                data-wow-delay="200ms"
              >
                <div className="icon">
                  <i class="fa-solid fa-cloud-sun-rain"></i>
                </div>
                <h4 className="title">Weather Analysis</h4>
                <p>
                  Harness the power of ERA5, in collaboration with the
                  Copernicus Climate Change Service (C3S), or upload custom
                  weather data to view and interrogate.
                </p>

                <ul style={{ fontSize: "16px" }}>
                  <li>
                    <FontAwesomeIcon
                      style={{ width: "8px" }}
                      color="#00a0c6"
                      icon={faCircle}
                    />{" "}
                    <strong>Wind Rose</strong> – View how wind speed and
                    direction are typically distributed at a particular location
                  </li>
                  <li>
                    <FontAwesomeIcon
                      style={{ width: "8px" }}
                      color="#00a0c6"
                      icon={faCircle}
                    />{" "}
                    <strong>Persistence Calculator</strong> – A limited interval
                    when weather conditions can be expected to be suitable for a
                    particular activity e.g. turbine maintenance.
                  </li>
                  <li>
                    <FontAwesomeIcon
                      style={{ width: "8px" }}
                      color="#00a0c6"
                      icon={faCircle}
                    />{" "}
                    <strong>Persistence Report</strong> – Easily download
                    persistence results in professional PDF report or in its raw
                    data form.
                  </li>
                </ul>
              </div>
            </div>
            <div style={{ margin: "16px 0" }} className="col-lg-6">
              <div className="slider-container">
                <Slider {...settings}>
                  <div onClick={() => handleFullScreenImage(Weather)}>
                    <label>Weather Analysis Results</label>
                    <img src={Weather} />
                  </div>
                  <div onClick={() => handleFullScreenImage(Persistence)}>
                    <label>Persistence Results</label>
                    <img src={Persistence} />
                  </div>
                  <div
                    onClick={() => handleFullScreenImage(PersistenceResults)}
                  >
                    <label>Persistence Report</label>
                    <img src={PersistenceResults} />
                  </div>
                </Slider>
              </div>
            </div>

            <div style={{ margin: "16px 0" }} className="col-lg-6">
              <div className="slider-container">
                <Slider {...settings}>
                  <div onClick={() => handleFullScreenImage(Elevation)}>
                    <label>Elevation Run</label>
                    <img src={Elevation} />
                  </div>
                  <div onClick={() => handleFullScreenImage(ElevationR1)}>
                    <label>Elevation Results</label>
                    <img src={ElevationR1} />
                  </div>
                  <div onClick={() => handleFullScreenImage(ElevationR2)}>
                    <label>Slope Results</label>
                    <img src={ElevationR2} />
                  </div>
                </Slider>
              </div>
            </div>
            <div style={{ margin: "16px 0" }} className="col-lg-6">
              <div
                className="
                    appie-single-service-2 appie-single-service-about
                    item-4
                    mt-30
                    wow
                    animated
                    fadeInUp
                  "
                data-wow-duration="2000ms"
                data-wow-delay="200ms"
              >
                <div className="icon">
                  <i class="fa-solid fa-arrow-down-up-across-line"></i>
                </div>
                <h4 className="title">Elevation Analysis</h4>
                <p>
                  Gain information on water depths offshore or elevation heights
                  onshore at a point, along a profile or across an area.
                </p>

                <ul style={{ fontSize: "16px" }}>
                  <li>
                    <FontAwesomeIcon
                      style={{ width: "8px" }}
                      color="#00a0c6"
                      icon={faCircle}
                    />{" "}
                    <strong>Onshore & Offshore Data</strong> – The data used is
                    from GEBCO (General Bathymetric Chart of the Oceans) and
                    uses Mean Sea Level (MSL) measurement with 15 arc-second
                    interval grids.
                  </li>
                  <li>
                    <FontAwesomeIcon
                      style={{ width: "8px" }}
                      color="#00a0c6"
                      icon={faCircle}
                    />{" "}
                    <strong>Slope Analysis</strong> – Evaluate the stability of
                    slopes of soil and rock-fill dams, embankments, excavated
                    slopes, and natural slopes in soil and rock.
                  </li>
                </ul>
              </div>
            </div>

            <div style={{ margin: "16px 0" }} className="col-lg-6">
              <div
                className="
                    appie-single-service-2 appie-single-service-about
                    mt-30
                    wow
                    animated
                    fadeInUp
                  "
                data-wow-duration="2000ms"
                data-wow-delay="200ms"
              >
                <div className="icon">
                  <i class="fa-solid fa-magnifying-glass"></i>
                </div>
                <h4 className="title">Data Searching</h4>
                <p>
                  Make it easier to view what you care about with our data
                  search widget. Filter through the following list of datasets:
                </p>

                <ul style={{ fontSize: "16px" }}>
                  <li>
                    <FontAwesomeIcon
                      style={{ width: "8px" }}
                      color="#00a0c6"
                      icon={faCircle}
                    />{" "}
                    <strong>Global Port Search</strong> – The World Port Index
                    shows locations, physical characteristics, facilities, and
                    services offered by major ports around the world.
                  </li>
                  <li>
                    <FontAwesomeIcon
                      style={{ width: "8px" }}
                      color="#00a0c6"
                      icon={faCircle}
                    />{" "}
                    <strong>AIS Data (Beta)</strong> – Visualise ship movements
                    with a 10 minutes refresh rate.
                  </li>
                  <li>
                    <FontAwesomeIcon
                      style={{ width: "8px" }}
                      color="#00a0c6"
                      icon={faCircle}
                    />{" "}
                    <strong>Power Plant Search (Beta)</strong> – Search for an
                    operational power plant whether it be a windfarm or a solar
                    farm.
                  </li>
                </ul>
              </div>
            </div>
            <div style={{ margin: "16px 0" }} className="col-lg-6">
              <div className="slider-container">
                <Slider {...settings}>
                  <div onClick={() => handleFullScreenImage(Ports)}>
                    <label>Port Search</label>
                    <img src={Ports} />
                  </div>
                  <div onClick={() => handleFullScreenImage(AIS)}>
                    <label>AIS Data</label>
                    <img src={AIS} />
                  </div>
                  <div onClick={() => handleFullScreenImage(WindFarmSearch)}>
                    <label>Renewable Energy Search</label>
                    <img src={WindFarmSearch} />
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
        {showVideo.state && (
          <PopupVideo
            imageSrc={showVideo.src}
            handler={(e) => handleShowVideo(e)}
          />
        )}
      </section>
    </>
  );
}

export default ServicesHomeEight;
