/**
 *
 * This code is written, owned and maintained by
 * Vekta Group Energy Division.
 *
 * © 2023, Vekta Group Energy Division.
 *
 */

import React, { useContext, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getNameList } from "country-list";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

import Select from "react-select";
import countryList from "react-select-country-list";
import { PlatformData } from "../../../imports/Context";

import Joyride, { STATUS } from "react-joyride";

import { logEvent } from "firebase/analytics";
import { analytics } from "../../../firebase";
import { StyledIconButton } from "../../../styles/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";

function DataSearch() {
  const [portSearch, setPortSearch] = useState(false);
  const [aisSearch, setAisSearch] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const countries = useMemo(() => countryList().getData(), []);

  const { platformData, setPlatformData } = useContext(PlatformData);

  const yesNoOptions = [
    { value: null, label: "Select..." },
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
    { value: "Unknown", label: "Unknown" },
  ];
  const portSize = [
    { value: null, label: "Select..." },
    { value: "Very Small", label: "Very Small" },
    { value: "Small", label: "Small" },
    { value: "Medium", label: "Medium" },
    { value: "Large", label: "Large" },
  ];
  const portType = [
    { value: null, label: "Select..." },
    { value: "River (Natural)", label: "River (Natural)" },
    { value: "Costal (Breakwater)", label: "Costal (Breakwater)" },
    { value: "River (Basins)", label: "River (Basins)" },
    { value: "Costal (Natural)", label: "Costal (Natural)" },
    { value: "Open Roadstead", label: "Open Roadstead" },
    { value: "River (Tide Gate)", label: "River (Tide Gate)" },
    { value: "Canal or Lake", label: "Canal or Lake" },
    { value: "Costal (Tide Gate)", label: "Costal (Tide Gate)" },
    { value: "Typhoon Harbor", label: "Typhoon Harbor" },
  ];
  const portShelter = [
    { value: null, label: "Select..." },
    { value: "Excellent", label: "Excellent" },
    { value: "Fair", label: "Fair" },
    { value: "Good", label: "Good" },
    { value: "None", label: "None" },
    { value: "Poor", label: "Poor" },
    { value: "Unknown", label: "Unknown" },
  ];

  const [runJoyride, setRunJoyride] = useState(false);
  const [{ widgetRun, steps }, setWidgetJoyride] = useState({
    widgetRun: false,
    steps: [
      {
        title: "Port Search",
        content: (
          <div>
            <img
              style={{ borderRadius: "8px", width: "-webkit-fill-available" }}
              src="/video/portSearch.gif"
            />
            <br></br>
            <p>
              Expand the section to display the ports on the map. Filter the
              search even more with the section dropdowns.
            </p>
          </div>
        ),
        locale: { skip: <strong aria-label="skip">SKIP</strong> },
        placement: "bottom",
        target: ".portSearch",
        disableBeacon: true,
      },
      {
        title: "AIS Search",
        content: (
          <div>
            <img
              style={{ borderRadius: "8px", width: "-webkit-fill-available" }}
              src="/video/aisSearch.gif"
            />
            <br></br>
            <p>
              AIS data is updated every ~10 minutes. Expand the section to view
              the data on the map. Select a data point to interrogate the
              information.
            </p>
          </div>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "bottom",
        target: ".aisSearch",
      },
    ],
  });

  useEffect(() => {
    console.log(searchParams);
    console.log(aisSearch);

    if (aisSearch && !searchParams.getAll("dataSearch").includes("ais")) {
      //Add to serach params
      setSearchParams({
        ...searchParams,
        dataSearch: [...searchParams.getAll("dataSearch"), "ais"],
      });

      /**
       *
       * GOOGLE ANALYTICS
       *
       */
      logEvent(analytics, "select_content", {
        content_type: "Data search - AIS",
      });
    }

    if (!aisSearch) {
      const values = searchParams.getAll("dataSearch");
      values.splice(values.indexOf("ais"), 1);

      setSearchParams({ ...searchParams, dataSearch: values });
    }
  }, [aisSearch]);

  useEffect(() => {
    if (portSearch && !searchParams.getAll("dataSearch").includes("port")) {
      setSearchParams({
        ...searchParams,
        dataSearch: [...searchParams.getAll("dataSearch"), "port"],
      });

      /**
       *
       * GOOGLE ANALYTICS
       *
       */
      logEvent(analytics, "select_content", {
        content_type: "Data search - Port",
      });
    }

    if (!portSearch) {
      const values = searchParams.getAll("dataSearch");
      values.splice(values.indexOf("port"), 1);

      setSearchParams({ ...searchParams, dataSearch: values });
    }
  }, [portSearch]);

  const handleReset = () => {
    setPlatformData({
      ...platformData,
      dataSearch: {
        ...platformData.dataSearch,
        change: null,
        cqlFilter: {
          CountryCode: { value: null, label: "Select..." },
          HarborSize: { value: null, label: "Select..." },
          HarborType: { value: null, label: "Select..." },
          ShelterAfforded: { value: null, label: "Select..." },
          Lifts_100_Tons: { value: null, label: "Select..." },
          Lifts_50_100Tons: { value: null, label: "Select..." },
          Lifts_25_49Tons: { value: null, label: "Select..." },
          Lifts_0_24Tons: { value: null, label: "Select..." },
          Cranes_Fixed: { value: null, label: "Select..." },
          Cranes_Mobile: { value: null, label: "Select..." },
          Cranes_Floating: { value: null, label: "Select..." },
        },
      },
    });
  };

  const handleTutorial = () => {
    setRunJoyride(true);
  };

  const handleJoyrideCallback = (data) => {
    const { status, type } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRunJoyride(false);
    }
  };

  return (
    <div className="dataSearch widgets">
      <Joyride
        continuous
        callback={handleJoyrideCallback}
        run={runJoyride}
        steps={steps}
        showSkipButton
        showProgress
        styles={{
          options: {
            primaryColor: "#009ec6",
            zIndex: 10000,
          },
        }}
      />

      <div style={{ display: "flex" }}>
        <h2>Search</h2>
        <StyledIconButton className="widgetTour" onClick={handleTutorial}>
          <FontAwesomeIcon icon={faQuestion} />
        </StyledIconButton>
      </div>

      <hr />

      <div className="portSearch inputSection">
        <div className="inputSection_heading">
          <div style={{ display: "flex", paddingLeft: "10px" }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
              {/* <!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --> */}
              <path d="M320 96a32 32 0 1 1 -64 0 32 32 0 1 1 64 0zm21.1 80C367 158.8 384 129.4 384 96c0-53-43-96-96-96s-96 43-96 96c0 33.4 17 62.8 42.9 80H224c-17.7 0-32 14.3-32 32s14.3 32 32 32h32V448H208c-53 0-96-43-96-96v-6.1l7 7c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L97 263c-9.4-9.4-24.6-9.4-33.9 0L7 319c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l7-7V352c0 88.4 71.6 160 160 160h80 80c88.4 0 160-71.6 160-160v-6.1l7 7c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-56-56c-9.4-9.4-24.6-9.4-33.9 0l-56 56c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l7-7V352c0 53-43 96-96 96H320V240h32c17.7 0 32-14.3 32-32s-14.3-32-32-32H341.1z" />
            </svg>
            <h4>Ports</h4>
          </div>

          <button
            // className="inputControlsHide portSearchHide"
            className={
              !portSearch ? "inputControlsHide" : "inputControlsHide open"
            }
            onClick={() => setPortSearch(!portSearch)}
          >
            <span className="left-bar"></span>
            <span className="right-bar"></span>
          </button>
        </div>

        <div
          //   className="portSearch_parameters hide_block"
          className={
            !portSearch
              ? "portSearch_parameters hide_block"
              : "portSearch_parameters"
          }
        >
          <div className="portShortList">
            <div className="input">
              <label>Country</label>

              <Select
                className="dataSearch_select "
                options={countries}
                value={platformData.dataSearch.cqlFilter.CountryCode}
                onChange={(e) => {
                  console.log(e);
                  setPlatformData({
                    ...platformData,
                    dataSearch: {
                      ...platformData.dataSearch,
                      change: !platformData.dataSearch.change,
                      cqlFilter: {
                        ...platformData.dataSearch.cqlFilter,
                        CountryCode: { label: e.label, value: e.label },
                      },
                    },
                  });
                }}
              />
            </div>
            <div className="portControlBtns">
              {/* <button className="btn tableView">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-table"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z" />
                </svg>
              </button> */}

              <button className="btn tableReset" onClick={handleReset}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  width="20"
                  height="20"
                >
                  {/* <!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --> */}
                  <path d="M256.455 8c66.269.119 126.437 26.233 170.859 68.685l35.715-35.715C478.149 25.851 504 36.559 504 57.941V192c0 13.255-10.745 24-24 24H345.941c-21.382 0-32.09-25.851-16.971-40.971l41.75-41.75c-30.864-28.899-70.801-44.907-113.23-45.273-92.398-.798-170.283 73.977-169.484 169.442C88.764 348.009 162.184 424 256 424c41.127 0 79.997-14.678 110.629-41.556 4.743-4.161 11.906-3.908 16.368.553l39.662 39.662c4.872 4.872 4.631 12.815-.482 17.433C378.202 479.813 319.926 504 256 504 119.034 504 8.001 392.967 8 256.002 7.999 119.193 119.646 7.755 256.455 8z" />
                </svg>
              </button>
            </div>
          </div>

          <div className="groupedInputs">
            <div className="input">
              <label>Harbour Size</label>
              <Select
                className="dataSearch_select "
                options={portSize}
                value={platformData.dataSearch.cqlFilter.HarborSize}
                onChange={(e) =>
                  setPlatformData((platformData) => ({
                    ...platformData,
                    dataSearch: {
                      ...platformData.dataSearch,
                      change: e.value,
                      cqlFilter: {
                        ...platformData.dataSearch.cqlFilter,
                        HarborSize: e,
                      },
                    },
                  }))
                }
              />
            </div>

            <div className="input">
              <label>Harbour Type</label>
              <Select
                className="dataSearch_select "
                options={portType}
                value={platformData.dataSearch.cqlFilter.HarborType}
                onChange={(e) =>
                  setPlatformData((platformData) => ({
                    ...platformData,
                    dataSearch: {
                      ...platformData.dataSearch,
                      change: e.value,
                      cqlFilter: {
                        ...platformData.dataSearch.cqlFilter,
                        HarborType: e,
                      },
                    },
                  }))
                }
              />
            </div>

            <div className="input">
              <label>Shelter Afforded</label>
              <Select
                className="dataSearch_select "
                options={portShelter}
                value={platformData.dataSearch.cqlFilter.ShelterAfforded}
                onChange={(e) =>
                  setPlatformData((platformData) => ({
                    ...platformData,
                    dataSearch: {
                      ...platformData.dataSearch,
                      change: e.value,
                      cqlFilter: {
                        ...platformData.dataSearch.cqlFilter,
                        ShelterAfforded: e,
                      },
                    },
                  }))
                }
              />
            </div>
          </div>

          <div className="portLifts_inputs">
            <div className="input">
              <label>Lift 100+ Tonnes</label>

              <Select
                className="dataSearch_select "
                options={yesNoOptions}
                value={platformData.dataSearch.cqlFilter["Lifts_100_Tons"]}
                onChange={(e) =>
                  setPlatformData((platformData) => ({
                    ...platformData,
                    dataSearch: {
                      ...platformData.dataSearch,
                      change: e.value,
                      cqlFilter: {
                        ...platformData.dataSearch.cqlFilter,
                        Lifts_100_Tons: e,
                      },
                    },
                  }))
                }
              />
            </div>

            <div className="input">
              <label>Lift 50 - 100 Tonnes</label>

              <Select
                className="dataSearch_select "
                options={yesNoOptions}
                value={platformData.dataSearch.cqlFilter["Lifts_50_100Tons"]}
                onChange={(e) =>
                  setPlatformData((platformData) => ({
                    ...platformData,
                    dataSearch: {
                      ...platformData.dataSearch,
                      change: e.value,
                      cqlFilter: {
                        ...platformData.dataSearch.cqlFilter,
                        Lifts_50_100Tons: e,
                      },
                    },
                  }))
                }
              />
            </div>

            <div className="input">
              <label>Lift 25 - 50 Tonnes</label>

              <Select
                className="dataSearch_select "
                options={yesNoOptions}
                value={platformData.dataSearch.cqlFilter["Lifts_25_49Tons"]}
                onChange={(e) =>
                  setPlatformData((platformData) => ({
                    ...platformData,
                    dataSearch: {
                      ...platformData.dataSearch,
                      change: e.value,
                      cqlFilter: {
                        ...platformData.dataSearch.cqlFilter,
                        Lifts_25_49Tons: e,
                      },
                    },
                  }))
                }
              />
            </div>

            <div className="input">
              <label>Lift 0 - 25 Tonnes</label>

              <Select
                className="dataSearch_select "
                options={yesNoOptions}
                value={platformData.dataSearch.cqlFilter["Lifts_0_24Tons"]}
                onChange={(e) =>
                  setPlatformData((platformData) => ({
                    ...platformData,
                    dataSearch: {
                      ...platformData.dataSearch,
                      change: e.value,
                      cqlFilter: {
                        ...platformData.dataSearch.cqlFilter,
                        Lifts_0_24Tons: e,
                      },
                    },
                  }))
                }
              />
            </div>
          </div>

          <div className="portCrane_inputs">
            <div className="input">
              <label>Fixed Crane</label>

              <Select
                className="dataSearch_select "
                options={yesNoOptions}
                value={platformData.dataSearch.cqlFilter["Cranes_Fixed"]}
                onChange={(e) =>
                  setPlatformData((platformData) => ({
                    ...platformData,
                    dataSearch: {
                      ...platformData.dataSearch,
                      change: e.value,
                      cqlFilter: {
                        ...platformData.dataSearch.cqlFilter,
                        Cranes_Fixed: e,
                      },
                    },
                  }))
                }
              />
            </div>

            <div className="input">
              <label>Mobile Crane</label>

              <Select
                className="dataSearch_select "
                options={yesNoOptions}
                value={platformData.dataSearch.cqlFilter["Cranes_Mobile"]}
                onChange={(e) =>
                  setPlatformData((platformData) => ({
                    ...platformData,
                    dataSearch: {
                      ...platformData.dataSearch,
                      change: e.value,
                      cqlFilter: {
                        ...platformData.dataSearch.cqlFilter,
                        Cranes_Mobile: e,
                      },
                    },
                  }))
                }
              />
            </div>

            <div className="input">
              <label>Floating Crane</label>

              <Select
                className="dataSearch_select "
                options={yesNoOptions}
                value={platformData.dataSearch.cqlFilter["Cranes_Floating"]}
                onChange={(e) =>
                  setPlatformData((platformData) => ({
                    ...platformData,
                    dataSearch: {
                      ...platformData.dataSearch,
                      change: e.value,
                      cqlFilter: {
                        ...platformData.dataSearch.cqlFilter,
                        Cranes_Floating: e,
                      },
                    },
                  }))
                }
              />
            </div>
          </div>

          <div className="portSearchTable">
            {/* <TableContainer component={Paper} style={{ width: "60%" }}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: 600, fontSize: "15px" }}>Port Name</TableCell>
                    <TableCell style={{ fontWeight: 600, fontSize: "15px" }}>Port Size</TableCell>
                    <TableCell style={{ fontWeight: 600, fontSize: "15px" }}>Port Type</TableCell>
                    <TableCell style={{ fontWeight: 600, fontSize: "15px" }}>Shelter</TableCell>
                    <TableCell style={{ fontWeight: 600, fontSize: "15px" }}>Lift 100+ Tonnes</TableCell>
                    <TableCell style={{ fontWeight: 600, fontSize: "15px" }}>Lift 50 - 100 Tonnes</TableCell>
                    <TableCell style={{ fontWeight: 600, fontSize: "15px" }}>Lift 25 - 50 Tonnes</TableCell>
                    <TableCell style={{ fontWeight: 600, fontSize: "15px" }}>Fixed Crane</TableCell>
                    <TableCell style={{ fontWeight: 600, fontSize: "15px" }}>Mobile Crane</TableCell>
                    <TableCell style={{ fontWeight: 600, fontSize: "15px" }}>Floating Crane</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {percentile.map((percent, key) => (
                    <TableRow>
                      <TableCell component="th" style={{ fontWeight: 600, fontSize: "15px" }}>
                        {percent.label}
                      </TableCell>
                      {platformData.weatherAnalysis.persistanceData.map((data) => {
                        if (String(data.window) === customOptions.window.value && String(data.wind) === customOptions.wind.value && String(data.wave) === customOptions.wave.value && String(data.tp) === customOptions.tp.value) {
                          return data !== null ? (
                            <>
                              {data.fullData[key].map((values) => (
                                <TableCell style={{ fontSize: "13px" }}>{String(values)}</TableCell>
                              ))}
                            </>
                          ) : null
                        }
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer> */}
          </div>
        </div>
      </div>

      <div className="aisSearch inputSection">
        <div className="inputSection_heading">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "10px",
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
              {/* <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --> */}
              <path d="M192 32c0-17.7 14.3-32 32-32H352c17.7 0 32 14.3 32 32V64h48c26.5 0 48 21.5 48 48V240l44.4 14.8c23.1 7.7 29.5 37.5 11.5 53.9l-101 92.6c-16.2 9.4-34.7 15.1-50.9 15.1c-19.6 0-40.8-7.7-59.2-20.3c-22.1-15.5-51.6-15.5-73.7 0c-17.1 11.8-38 20.3-59.2 20.3c-16.2 0-34.7-5.7-50.9-15.1l-101-92.6c-18-16.5-11.6-46.2 11.5-53.9L96 240V112c0-26.5 21.5-48 48-48h48V32zM160 218.7l107.8-35.9c13.1-4.4 27.3-4.4 40.5 0L416 218.7V128H160v90.7zM306.5 421.9C329 437.4 356.5 448 384 448c26.9 0 55.4-10.8 77.4-26.1l0 0c11.9-8.5 28.1-7.8 39.2 1.7c14.4 11.9 32.5 21 50.6 25.2c17.2 4 27.9 21.2 23.9 38.4s-21.2 27.9-38.4 23.9c-24.5-5.7-44.9-16.5-58.2-25C449.5 501.7 417 512 384 512c-31.9 0-60.6-9.9-80.4-18.9c-5.8-2.7-11.1-5.3-15.6-7.7c-4.5 2.4-9.7 5.1-15.6 7.7c-19.8 9-48.5 18.9-80.4 18.9c-33 0-65.5-10.3-94.5-25.8c-13.4 8.4-33.7 19.3-58.2 25c-17.2 4-34.4-6.7-38.4-23.9s6.7-34.4 23.9-38.4c18.1-4.2 36.2-13.3 50.6-25.2c11.1-9.4 27.3-10.1 39.2-1.7l0 0C136.7 437.2 165.1 448 192 448c27.5 0 55-10.6 77.5-26.1c11.1-7.9 25.9-7.9 37 0z" />
            </svg>
            <h4>Vessels</h4>
          </div>

          <button
            // className="inputControlsHide aisSearchHide"
            className={
              !aisSearch ? "inputControlsHide" : "inputControlsHide open"
            }
            onClick={() => setAisSearch(!aisSearch)}
          >
            <span className="left-bar"></span>
            <span className="right-bar"></span>
          </button>
        </div>

        <div
          className={
            !aisSearch
              ? "aisSearch_parameters hide_block"
              : "aisSearch_parameters"
          }
        >
          <p>
            Live Automatic Identification System (AIS) vessel data. The refresh
            rate is currently 10 min.
          </p>
          <strong>
            <p>
              This is a new feature for this tool and still in beta phase. We
              are working to improve this feature to add vessel search and
              increase refresh rates.
            </p>
          </strong>
        </div>
      </div>
    </div>
  );
}

export default DataSearch;
