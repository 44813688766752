/**
 *
 * This code is written, owned and maintained by
 * Vekta Group Energy Division.
 *
 * © 2023, Vekta Group Energy Division.
 *
 */

import React, { useContext, useEffect, useState } from "react";
import Plot from "react-plotly.js";
import Plotly from "plotly.js-dist-min";
import { styled } from "styled-components";
import { BounceLoader, ScaleLoader } from "react-spinners";

import { PlatformData, LoadingStatus } from "../../../imports/Context";

import SingleLinePlot from "./SingleLinePlot";
import ThreeDPlot from "./ThreeDPlot";
import ElevationStats from "./Stats";

import { elevationAnalysisAPI } from "./helperFunctions";
import LoadingOverlay from "react-loading-overlay-ts";

import Joyride, { STATUS } from "react-joyride";

import { logEvent } from "firebase/analytics";
import { analytics } from "../../../firebase";
import { StyledIconButton } from "../../../styles/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";

const Panel = styled.div`
  width: 100%px;
  height: 100%;
  /* border: 3px solid blue; */
`;

const StyledPlot = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid black;
`;

const roundToOneDecimal = (value) => {
  const num = parseFloat(value);
  if (!isNaN(num)) {
    console.log(num);
    return num.toFixed(1);
  }
  return value; // Return the original value if it's not a number
};

function ElevationAnalysis() {
  const { platformData, setPlatformData } = useContext(PlatformData);
  const { loadingStatus, setLoadingStatus } = useContext(LoadingStatus);
  const [plotType, setPlotType] = useState(null);
  const [input, setInput] = useState(null);
  const [output, setOutput] = useState({ Data: null });
  const [addSlope, setAddSlope] = useState(false);
  const [runJoyride, setRunJoyride] = useState(false);

  const [{ widgetRun, steps }, setWidgetJoyride] = useState({
    widgetRun: false,
    steps: [
      {
        title: "Elevation Data Source",

        content: (
          <div>
            {/* <img style={{ borderRadius: "8px", width: "-webkit-fill-available" }} src="/video/elevation_tutorial_1_data_select.gif" /> */}
            <br></br>
            <p>
              When new data sources are added you will be able to select them
              here.
            </p>
          </div>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "left",
        target: "#elevation_data_search",
        disableBeacon: true,
      },
      {
        title: "Select Polygon, Line or Point",
        content: (
          <div>
            <img
              style={{ borderRadius: "8px", width: "-webkit-fill-available" }}
              src="/video/elevation_tutorial_2_select_polygon.gif"
            />
            <br></br>
            <p>
              Change to select mode and click a polygon, line or point while the
              elevation widget is active to get the elevation data.
            </p>
          </div>
        ),
        locale: { skip: <strong aria-label="skip">SKIP</strong> },
        placement: "left",
        target: ".draw_select",
        disableBeacon: true,
      },
      {
        title: "Plots",
        content: (
          <div>
            <img
              style={{ borderRadius: "8px", width: "-webkit-fill-available" }}
              src="/video/elevation_tutorial_3_toggle_slope.gif"
            />
            <br></br>
            <p>
              Change to select mode and click a polygon, line or point while the
              the elevation widget is active to get the elevation data.
            </p>
          </div>
        ),
        locale: { skip: <strong aria-label="skip">SKIP</strong> },
        placement: "bottom",
        target: "#elevation_plot",
        disableBeacon: true,
      },
      {
        title: "Run with Right Click ",
        content: (
          <div>
            <img
              style={{ borderRadius: "8px", width: "-webkit-fill-available" }}
              src="/video/elevation_tutorial_4_right_click.gif"
            />
            <br></br>
            <p>
              Alternatively you can right click on a point, line or polygon and
              run the elevation widget.
            </p>
          </div>
        ),
        locale: { skip: <strong aria-label="skip">SKIP</strong> },
        placement: "center",
        target: ".leaflet-container",
        disableBeacon: true,
      },
    ],
  });

  //
  useEffect(() => {
    const inputJSON = platformData.elevationAnalysis.inputJSON;
    setInput(inputJSON);
    const callAPI = async () => {
      const outputJSON = await elevationAnalysisAPI(
        inputJSON,
        platformData,
        setPlatformData
      );

      if (outputJSON.Data && outputJSON.Data[0] !== "NA") {
        setOutput(outputJSON);
      } else {
        alert(outputJSON.Msg);
      }

      setLoadingStatus({ loading: false, loadingMsg: "" });
    };

    if (inputJSON.geometry && inputJSON.geometry.type) {
      setPlotType(inputJSON.geometry.type);
      callAPI();
    }
  }, [platformData.elevationAnalysis.inputJSON]);

  const handleClearPlot = () => {
    setOutput({ Data: null });
    setPlatformData({
      ...platformData,
      elevationAnalysis: {
        ...platformData.elevationAnalysis,
        outputJSON: null,
      },
    });
  };

  const handleTutorial = () => {
    setRunJoyride(true);
  };

  const handleJoyrideCallback = (data) => {
    const { status, type } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRunJoyride(false);
    }
  };

  return (
    <LoadingOverlay
      active={loadingStatus.loading}
      classNamePrefix="main_sidebarLoader_"
      className="widgets"
      spinner={<ScaleLoader color="white" />}
    >
      <div className="elevationAnalysis">
        <div style={{ display: "flex" }}>
          <h2>Elevation</h2>
          <StyledIconButton className="widgetTour" onClick={handleTutorial}>
            <FontAwesomeIcon icon={faQuestion} />
          </StyledIconButton>
        </div>
        <hr />

        <Joyride
          continuous
          callback={handleJoyrideCallback}
          run={runJoyride}
          steps={steps}
          showSkipButton
          showProgress
          styles={{
            options: {
              primaryColor: "#009ec6",
              zIndex: 10000,
            },
          }}
        />

        <div id="elevation_data_search" className="aisSearch inputSection">
          <div className="inputSection_heading">
            <div
              style={{
                display: "flex",
                paddingLeft: "10px",
                alignItems: "center",
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                {/* <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --> */}
                <path d="M448 80v48c0 44.2-100.3 80-224 80S0 172.2 0 128V80C0 35.8 100.3 0 224 0S448 35.8 448 80zM393.2 214.7c20.8-7.4 39.9-16.9 54.8-28.6V288c0 44.2-100.3 80-224 80S0 332.2 0 288V186.1c14.9 11.8 34 21.2 54.8 28.6C99.7 230.7 159.5 240 224 240s124.3-9.3 169.2-25.3zM0 346.1c14.9 11.8 34 21.2 54.8 28.6C99.7 390.7 159.5 400 224 400s124.3-9.3 169.2-25.3c20.8-7.4 39.9-16.9 54.8-28.6V432c0 44.2-100.3 80-224 80S0 476.2 0 432V346.1z" />
              </svg>
              <h4>Data</h4>
            </div>
          </div>

          <div className="input">
            <label className="labelInput">Source</label>
            <select>
              <option value="selectDataPoint">
                GEBCO/EMODNET/SRTM (Mosaic)
              </option>
            </select>
          </div>
        </div>

        <div id="elevation_plot" class="waterDepthDraw inputSection">
          <div class="inputSection_heading">
            <div style={{ display: "flex", paddingLeft: "10px" }}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                {/* <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --> */}
                <path d="M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64V400c0 44.2 35.8 80 80 80H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H80c-8.8 0-16-7.2-16-16V64zm406.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L320 210.7l-57.4-57.4c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L240 221.3l57.4 57.4c12.5 12.5 32.8 12.5 45.3 0l128-128z" />
              </svg>
              {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
              <path
                d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z"
              />
            </svg> */}
              <h4>Plots</h4>
            </div>
          </div>

          <div className="elevationLimitation"></div>

          <Panel>
            {plotType === "Point" && input ? (
              <div
                className="elevationAnalysis_point"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <h4 className="pointData">
                  Elevation:{" "}
                  {output.Data && output.Data[0] && output.Data[0].Elevation
                    ? output.Data[0].Elevation + " m"
                    : ""}{" "}
                </h4>
              </div>
            ) : null}

            {plotType === "LineString" && output.Data ? (
              <SingleLinePlot data={output.Data} slope={addSlope} />
            ) : null}
            {plotType === "Polygon" && output.Data ? (
              <ThreeDPlot
                data={output.Data}
                Latitudes={output.Latitude}
                Longitudes={output.Longitude}
                Longlabels={output.Longlabels}
                LatLabels={output.Latlabels}
                Bbox={output.Bbox}
                slope={addSlope}
              />
            ) : null}
          </Panel>

          <Panel>
            {plotType === "Polygon" && output.Data ? (
              <ElevationStats
                Medelevation={roundToOneDecimal(output.Medelevation)}
                Minelevation={roundToOneDecimal(output.Minelevation)}
                Maxelevation={roundToOneDecimal(output.Maxelevation)}
              />
            ) : null}
          </Panel>

          <div class="inputSection_body">
            <div class="additionalDrawBtns">
              <button onClick={handleClearPlot}>Clear</button>

              <div class="checkboxGroup">
                {/* <div>
                <input type="checkbox" id="{{ ns('gis_depthBounds') }}" />
                <label>Apply Depth Bounds</label>
              </div> */}

                <div>
                  <input
                    type="checkbox"
                    class="gis_includeSlope"
                    onChange={(e) => {
                      setAddSlope(e.target.checked);
                      /**
                       *
                       * GOOGLE ANALYTICS
                       *
                       */
                      logEvent(analytics, "select_content", {
                        content_type: "Elevation slope",
                      });
                    }}
                  />
                  <label style={{ paddingLeft: "5px" }}>Include Slope</label>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{ display: "none", paddingLeft: "10px" }}
            id="{{ ns('waterDepthBoundingBox') }}"
            class="waterDepthBoundingBox"
          >
            <h4>Depth Bounds</h4>

            <div class="input">
              <label>Lower Bound</label>
              <input
                type="number"
                placeholder="e.g. -80"
                id="{{ ns('gis_depthLowerB') }}"
              />
            </div>

            <div class="input">
              <label>Upper Bound</label>
              <input
                type="number"
                placeholder="e.g. 20"
                id="{{ ns('gis_depthUpperB') }}"
              />
            </div>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
}

export default ElevationAnalysis;
