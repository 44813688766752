import React from 'react';
import * as XLSX from 'xlsx';
import { PrimeButton } from '../../../styles/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleDown } from '@fortawesome/free-solid-svg-icons';

const ExcelGenerator = ({ meta, coords, site, triggerGoogleAnalytics }) => {
  const generateExcel = () => {
    triggerGoogleAnalytics()
    const wb = XLSX.utils.book_new();


    console.log(coords);
    console.log(site);
    console.log(meta);



    if (!site.osp) {
      site.osp = [];
    }

    // Transpose the data to have latitudes and longitudes in separate rows
    //   const transposedData = [meta].reduce((acc, item, index) => {
    //     const keys = Object.keys(item);
    //     keys.forEach(key => {
    //         const values = Object.values(item[key]);
    //         values.forEach((value, valueIndex) => {
    //             if (!acc[valueIndex]) {
    //                 acc[valueIndex] = {};
    //             }
    //             if (valueIndex < 2) {
    //                 acc[valueIndex][`Column ${index + 1}`] = key;
    //             }
    //             acc[valueIndex][`Column ${index + 2}`] = value;
    //         });
    //     });
    //     return acc;
    // }, []);


    // TODO: ADD METADATA SHEET
    // Add meta data sheet
    // const wsMeta = XLSX.utils.json_to_sheet(transposedData);
    // XLSX.utils.book_append_sheet(wb, wsMeta, 'Meta');


    // Add 'coords' sheet
    const wsCoords = XLSX.utils.json_to_sheet([...coords.coords, ...site.osp]);
    XLSX.utils.book_append_sheet(wb, wsCoords, 'Turbines');

    // Add 'cables' sheet
    // const wsCables = XLSX.utils.json_to_sheet(site.cables);
    let wsCables = XLSX.utils.aoa_to_sheet(
      [
        ["id", "Cable Nodes", "Length"] // Header row
      ].concat(site.cables.map(
        ({ id, geo, length }) => {
          console.log(geo);
          let formatted = ''
          for (let i = 0; i < geo.length; i++) {
            const element = geo[i];
            formatted = formatted + `(${element.lat}, ${element.lng}), `;
          }
          return ([id, formatted, length])
        } // [name, age, car[0], car[1], ... ]
      ))
    )
    XLSX.utils.book_append_sheet(wb, wsCables, 'Cables');

    XLSX.writeFile(wb, 'YieldData.xlsx');
  };

  return (
    <PrimeButton onClick={generateExcel} style={{ width: "48%" }}>
      <FontAwesomeIcon size="xl" color="white" icon={faCircleDown} />
      Data
    </PrimeButton>
  );
};

export default ExcelGenerator;
