/**
 * 
 * This code is written, owned and maintained by 
 * Vekta Group Energy Division.
 * 
 * © 2023, Vekta Group Energy Division.
 * 
 */

import Papa from "papaparse";
const apiHeaders = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
    'Access-Control-Request-Method': 'GET, POST, DELETE, PUT, OPTIONS',
}

export const getPersistanceResults = ({ window, height, period, speed, weatherSelect, platformData, loadingStatus, setLoadingStatus, user }) => {
    var dataloc = "gcp"
    var coordinates = platformData.weatherAnalysis.pointClick
    if (weatherSelect === "uploadWeather") {
        dataloc = "upload"
        coordinates = [0,0]
    } 

    const persistenceJson = {
        "type": "Feature",
        "geometry": {
            "type": "Point",
            "coordinates": coordinates
        },
        "properties": {
            "name": "Sample Point",
            "dataloc": dataloc,
            "window": window,
            "wave": height,
            "tp": period,
            "wind": speed,
            "windHeight": (isNaN(platformData.weatherAnalysis.windHeight)) ? 100 : Number(platformData.weatherAnalysis.windHeight),
            "uid": user.uid
        }
    }

    console.log(persistenceJson);

    return new Promise((resolve, reject) => {
        fetch("https://vekdig-hiibq2h6dq-nw.a.run.app/persistence", {
            method: "POST",
            headers: apiHeaders,
            body: JSON.stringify(persistenceJson)
        })
            .then((response) => {
                if (response.status === 401) {
                    resolve(0);
                } else if (response.status === 200) {
                    response.json().then((data) => {
                        resolve(data);
                    });
                } else if (response.status === 500) {
                    setLoadingStatus({ loading: false, loadingMsg: "" })
                    alert("An Error Has Occured, Please Try Again Later")
                }
                return response;
            })
            .catch((err) => {
                console.log(err);
            });
    });
}

export const getERA5Data = ({ platformData, setDownloadingData }) => {

    if (platformData.weatherAnalysis.pointClick.length > 0) {
        const pointDataJson = {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": platformData.weatherAnalysis.pointClick
            },
            "properties": {
                "name": "Sample Point",
                "dataloc": "gcp",
                "weatherdatatype": "weather",
                "windHeight": (isNaN(platformData.weatherAnalysis.windHeight)) ? 100 : Number(platformData.weatherAnalysis.windHeight)
            }
        }

        const call = new Promise((resolve, reject) => {
            fetch("https://vekdig-hiibq2h6dq-nw.a.run.app/weather", {
                method: "POST",
                headers: apiHeaders,
                body: JSON.stringify(pointDataJson)
            })
                .then((response) => {
                    if (response.status === 401) {
                        resolve(0);
                    } else if (response.status === 200) {
                        response.json().then((data) => {
                            const blob = new Blob([Papa.unparse(data)]);
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');

                            a.href = url;
                            a.download = "WeatherPoint[" + platformData.weatherAnalysis.pointClick[0] + "_" + platformData.weatherAnalysis.pointClick[1] + "].csv";
                            a.click();
                            window.URL.revokeObjectURL(url);

                            setDownloadingData(false)
                        });
                    } else if (response.status === 503) {
                        alert("Sorry for the inconvendece")
                    }
                 })
                .catch((err) => {
                    console.log(err);
                });
        });
    }
}

export const formatPersistenceData = (persistence, percentage, months) => {
    var data = []
    var combo = persistence.PersistenceCombinations

    for (let i = 0; i < combo.length; i++) {
        const splitPercentile = [
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            []
        ]

        const percentile = persistence.Persistence["W" + combo[i].W.toString()]["wind" + combo[i].wind.toString()]["wave" + combo[i].wave.toString()]["tp" + combo[i].tp.toString()]

        for (let j = 0; j < percentage.length; j++) {
            for (let k = 0; k < months.length; k++) {
                for (let l = 0; l < percentile.length; l++) {
                    if (percentile[l].Percent === percentage[j].value && percentile[l].Month === months[k]) {
                        splitPercentile[j].push(percentile[l].Value.toFixed(2))
                    }
                }
            }
        }
        data.push({
            window: combo[i].W.toString(),
            wind: combo[i].wind.toString(),
            wave: combo[i].wave.toString(),
            tp: combo[i].tp.toString(),
            fullData: splitPercentile
        })
    }
    return data
}

export const getUploadedWeatherData = (uid, setPlatformData, setLoadingStatus) => {
    const weatherJson = {
        "type": "Feature",
        "geometry": {
            "type": "Point",
            "coordinates": [0, 0]
        },
        "properties": {
            "name": "Sample Point",
            "dataloc": "upload",
            "weatherdatatype": "windrose",
            "windHeight": 100,
            "uuid": uid
        }
    }

    return new Promise((resolve, reject) => {
        fetch("https://vekdig-hiibq2h6dq-nw.a.run.app/weather", {
            method: "POST",
            headers: apiHeaders,
            body: JSON.stringify(weatherJson)

        })
            .then((response) => {
                if (response.status === 401) {
                    resolve(0);
                } else if (response.status === 200) {
                    response.json().then((windRose) => {
                        weatherJson.properties.weatherdatatype = "avgwindspeed"
                        fetch("https://vekdig-hiibq2h6dq-nw.a.run.app/weather", {
                            method: "POST",
                            headers: apiHeaders,
                            body: JSON.stringify(weatherJson)

                        })
                            .then((response) => {
                                if (response.status === 401) {
                                    resolve(0);
                                } else if (response.status === 200) {
                                    response.json().then((windSpeed) => {
                                        setPlatformData((platformData) => ({
                                            ...platformData,
                                            ["activeWidget"]: "WeatherAnalysis",
                                            ["sidePanelOpen"]: true,
                                            ["weatherAnalysis"]: {
                                                ...platformData.weatherAnalysis,
                                                ["pointClick"]: [parseFloat(windRose.ERALong[0]), parseFloat(windRose.ERALat[0])],
                                                ["windSpeedData"]: windSpeed,
                                                ["windRoseData"]: windRose
                                            }
                                        }));

                                        setLoadingStatus({ loading: false, loadingMsg: "" })
                                    });
                                }
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    });
}

export const callWeatherAPI = (platformData, setPlatformData, setLoadingStatus) => {
    const weatherJson = {
        "type": "Feature",
        "geometry": {
            "type": "Point",
            "coordinates": [
                platformData.weatherAnalysis.pointClick[0],
                platformData.weatherAnalysis.pointClick[1]
            ]
        },
        "properties": {
            "name": "Sample Point",
            "dataloc": "gcp",
            "weatherdatatype": "windrose",
            "windHeight": (isNaN(platformData.weatherAnalysis.windHeight)) ? 100 : Number(platformData.weatherAnalysis.windHeight)
        }
    }

    return new Promise((resolve, reject) => {
        fetch("https://vekdig-hiibq2h6dq-nw.a.run.app/weather", {
            method: "POST",
            headers: apiHeaders,
            body: JSON.stringify(weatherJson)

        })
            .then((response) => {
                if (response.status === 401) {
                    resolve(0);
                } else if (response.status === 200) {
                    response.json().then((windRose) => {
                        weatherJson.properties.weatherdatatype = "avgwindspeed"
                        fetch("https://vekdig-hiibq2h6dq-nw.a.run.app/weather", {
                            method: "POST",
                            headers: apiHeaders,
                            body: JSON.stringify(weatherJson)

                        })
                            .then((response) => {
                                if (response.status === 401) {
                                    resolve(0);
                                } else if (response.status === 200) {
                                    response.json().then((windSpeed) => {
                                        setPlatformData((platformData) => ({
                                            ...platformData,
                                            ["activeWidget"]: "WeatherAnalysis",
                                            ["sidePanelOpen"]: true,
                                            ["weatherAnalysis"]: {
                                                ...platformData.weatherAnalysis,
                                                ["pointClick"]: [parseFloat(windRose.ERALong[0]), parseFloat(windRose.ERALat[0])],
                                                ["windSpeedData"]: windSpeed,
                                                ["windRoseData"]: windRose
                                            }
                                        }));

                                        setLoadingStatus({ loading: false, loadingMsg: "" })
                                    });
                                }
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    });
}