/**
 *
 * This code is written, owned and maintained by
 * Vekta Group Energy Division.
 *
 * © 2023, Vekta Group Energy Division.
 *
 */

import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import base64 from "react-native-base64";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  MapContainer,
  LayersControl,
  TileLayer,
  useMapEvents,
  WMSTileLayer,
  Popup,
  Pane,
  useMap,
  FeatureGroup,
  Circle,
} from "react-leaflet";

import $ from "jquery";
import L, { map } from "leaflet";
// import 'leaflet-tilelayer-mbtiles-ts';
// import "Leaflet.TileLayer.MBTiles";
import { v4 as uuidv4 } from "uuid";

import "leaflet/dist/leaflet.css";
import DrawControlPanel from "./DrawControlPanel";
import styled from "styled-components";

import { polylineMeasure } from "leaflet.polylinemeasure";

import {
  convertToGeoJSON,
  loadIcons,
  zoomToLayer,
  showSelected,
  hideSelected,
  addMeasureTool,
  addLegendViewer,
  formatCqlFilter,
  addUploadedLayer,
  getLayerOrder,
  createERA5,
  addQueryLayer,
  removeQueryLayer,
  escape,
  renderBoard,
  addWMSLayer,
} from "./helperFunctions";

import * as GeoProvider from "../../providers/GeoProvider";
import { PlatformData, LoadingStatus } from "../../imports/Context";

import WindfarmSearch from "./windfarmSearch/WindfarmSearch";
import Board from "./Board";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, logout } from "../../firebase";
import { WMSTileLayerWithHeader } from "./customWMS";
var bbox = require("geojson-bbox");

const StyledMapContainer = styled.div`
  /* position: fixed; */
  height: 100%;
  width: 100%;

  .leaflet-container,
  .leaflet-marker-icon,
  .leaflet-polyline,
  .my-polygon {
    height: 100%;
    cursor: ${(props) => props.cursor} !important;
    outline: none;
  }
`;

function Map() {
  const position = [55.9533, -3.1883];
  const { BaseLayer, Overlay } = LayersControl;
  const mapRef = useRef();
  const location = useLocation();

  const { platformData, setPlatformData } = useContext(PlatformData);
  const { loadingStatus, setLoadingStatus } = useContext(LoadingStatus);

  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedLayer, setSelectedLayer] = useState(null);
  const [layerQuery, setLayerQuery] = useState({
    layerName: "",
    layerSource: {},
  });
  const [aisData, setAisData] = useState({ layerName: "", layerSource: {} });
  const [portData, setPortData] = useState({ layerName: "", layerSource: {} });
  const [filterData, setFilterData] = useState({
    layerName: "",
    layerSource: {},
  });

  const [user, loading, error] = useAuthState(auth);

  //====================================================

  const [points, setPoints] = useState({});
  const [lines, setLines] = useState({});
  const [polygons, setPolygons] = useState({});

  const [activePoint, setActivePoint] = useState(null);
  const [activeLine, setActiveLine] = useState(null);
  const [activePolygon, setActivePolygon] = useState(null);
  const [activeRectangle, setActiveRectangle] = useState(null);

  const [mode, setMode] = useState("default");
  const [cursor, setCursor] = useState("default");

  const [selected, setSelected] = useState(null);

  const [board, setBoard] = useState({
    // Geometries
    points: [],
    lines: [],
    polygons: [],

    // Active Geometries ID's
    activePoint: null,
    activeLine: null,
    activePolygon: null,

    // SpecialPolygon
    activeRectangle: null,

    // Utilities
    mode: "default",
    cursor: "default",
    hoveredPoint: null,

    // Edit Tools
    multiMove: null,
    cursorPos: null,
    moveStartPos: null,
    // Icon Styles
    iconTypes: loadIcons(),
  });

  const [uploadedData, setUploadedData] = useState(null);

  const configRef = useRef({
    pane: "Board",
    layer: "Board",
    color: "red",
    radius: 5,
    weight: 1,
    drawingLayer: uuidv4(),
    guideTriangle: "guideTriangle",
    guideLine: "guideLine",
    map: mapRef.current,
  });

  const geowebcache = [
    "Bathymetry:GLOBAL_Offshore-Contours",
    "Bathymetry:GLOBAL_Offshore-Contours-10m",
    "Boundaries:GLOBAL_Administrative-Boundaries",
    "Boundaries:GLOBAL_Governmental-Boundaries",
    "ERA5_Data:GLOBAL_ERA5-Data-Points",
    "Geology:GLOBAL_Active-Faults",
    "Management_Areas_Marine:EUR_Maritime-Boundaries",
    "Management_Areas_Marine:GLOBAL_12-Nautical-Mile-Boundary",
    "Management_Areas_Marine:GLOBAL_12-Nautical-Mile-Boundary",
    "Management_Areas_Marine:GLOBAL_Archipelagic-Waters",
    "Power_Infrastructure_-_OSM:GLOBAL_Power-Line",
    "Sea_Traffic:GLOBAL_Shipping-Density",
    "Wrecks_And_Weapon_Dumps:GLOBAL_Shipwrecks-and-Obstructions-Points",
    "Management_Areas_Marine:GLOBAL_Exclusive-Economic-Zones-Polygon",
    "Marine_Life_and_Habitats:GLOBAL_Coral-Reefs",
    "Marine_Life_and_Habitats:GLOBAL_Mangroves",
    "Marine_Life_and_Habitats:GLOBAL_Saltmarsh-Polygons",
    "Marine_Life_and_Habitats:GLOBAL_Seagrass-Polygons",
  ];

  const resetQueryLayer = () => {
    const base = $("#projectJSTree").jstree("get_checked");

    if (base.length > 0) {
      const formattedTree = [];
      for (let i = 0; i < base.length; i++) {
        var node = $("#projectJSTree").jstree().get_node(base[i]);

        if (node.type !== "group") {
          let url =
            process.env.REACT_APP_GEO_SERVER_URL +
            node.id.split(":")[0] +
            "/wms?";
          if (geowebcache.includes(node.id))
            url = process.env.REACT_APP_GEO_SERVER_URL_GEOWEBCACHE;

          formattedTree.push({
            type: node.type,
            name: node.text,
            id: node.id,
            opacity: 100,
            range: [null, null],
            query: false,
            url: url,
          });
        }
      }

      setPlatformData((platformData) => ({
        ...platformData,
        layerControl: {
          ...platformData.layerControl,
          activeLayers: formattedTree,
        },
      }));
    } else {
      let formattedTree = [...platformData.layerControl.activeLayers];

      formattedTree.forEach((layer, i) => {
        if (layer.query) formattedTree[i].query = false;
      });

      setPlatformData((platformData) => ({
        ...platformData,
        layerControl: {
          ...platformData.layerControl,
          activeLayers: formattedTree,
        },
      }));
    }

    removeQueryLayer(layerQuery);
    setSelectedLayer(null);
    setLayerQuery({ layerName: "", layerSource: {} });
  };

  useEffect(() => {
    if (mapRef.current) {
      /**
       *
       * Adding additional controls to leaflet map
       *
       */
      addMeasureTool(mapRef.current);
      addLegendViewer(mapRef.current);

      setPlatformData((platformData) => ({
        ...platformData,
        ["mapStatus"]: { zoom: null, ready: true },
      }));

      mapRef.current.invalidateSize();
      mapRef.current.attributionControl.setPrefix(
        `Powered By &copy; <a href="https://vektagroup.com/" target="_black"> Vekta Group </a> V 2.0.6`
      );

      // let mbtiles = new L.TileLayer.MBTiles('https://storage.googleapis.com/vekta_uploaded/SPEN_Cable_EHV_SPD.mbtiles');
      // mbtiles = mbTiles('https://storage.googleapis.com/vekta_uploaded/SPEN_Cable_EHV_SPD.mbtiles', { zIndex: 100 });

      // mbtiles.addTo(mapRef.current);

      // var mb = L.tileLayer.mbTiles('https://raw.githubusercontent.com/evantdailey/map_testing/master/2016668FA.mbtiles').addTo(mapRef.current);

      // mb.on('databaseloaded', function (ev) {
      //   console.info('MBTiles DB loaded', ev);
      // });
      // mb.on('databaseerror', function (ev) {
      //   console.info('MBTiles DB error', ev);
      // });

      /*
       *
       * Creation of ERA5 Grid that is then hidden to only be displayed
       * on the map when on the weather widget
       *
       */
      createERA5(
        mapRef.current,
        platformData,
        setPlatformData,
        setLoadingStatus
      );
      $(".leaflet-era5-grid-pane").hide();

      /**
       *
       * Creation of geoJson object for uploaded shapefiles
       *
       */
      setUploadedData(
        L.geoJSON(null, {
          pane: "uploaded-layer",
          pointToLayer: function (point, latlng) {
            L.circle([latlng.lat, latlng.lng], 1000, {
              stroke: "#007592",
              color: "#009ec6",
              pane: "uploaded-layer",
            })
              .bindTooltip(function () {
                var popupInfo = "";

                Object.keys(point.properties).map((propVal) => {
                  popupInfo =
                    popupInfo +
                    "<strong>" +
                    propVal +
                    "</strong>" +
                    ": " +
                    point.properties[propVal] +
                    "<br>";
                });

                return popupInfo;
              })
              .addTo(mapRef.current);
          },
        })
      );
    }
  }, [mapRef.current]);

  useEffect(() => {
    $(document).on("click", ".widgetBtn", () => {
      console.log("Map needs to change size");
      if (mapRef.current) {
        setTimeout(function () {
          mapRef.current.invalidateSize();
        }, 400);
      }
    });
  }, []);

  useEffect(() => {
    if (
      mode === "line" ||
      mode === "point" ||
      mode === "pointSnap" ||
      mode === "lineSnap" ||
      mode === "snapPoint" ||
      mode === "snapLine" ||
      mode === "polygon" ||
      mode === "snapPolygon" ||
      mode === "rectangle" ||
      mode === "locationMarker"
    ) {
      setCursor("crosshair");
    } else {
      setCursor("default");
    }

    if (
      mode !== "line" &&
      mode !== "snapLine" &&
      mode !== "polygon" &&
      mode !== "snapPolygon" &&
      mode !== "move" &&
      mode !== "rectangle"
    ) {
      setActivePoint(null);
      setActivePolygon(null);
      setActiveRectangle(null);
    }
  }, [mode]);

  /**
   *
   * Widget Control over Map
   *
   * Layer Control --> jstree event listeners + toolbar buttons
   * Weather Anaylsis --> ERA5 Grid
   *
   */
  useEffect(() => {
    $(".leaflet-era5-grid-pane").hide();

    // const selectedLayer = $("#projectJSTree").jstree().get_selected()[0];

    if (location.pathname === "/vekta-digital/LayerControl") {
      /**
       *
       * jsTree trigger events
       *
       */
      $("#projectJSTree").on("click", ".jstree-anchor", function (e) {
        if (!e.target.className.includes("jstree-checkbox")) {
          var id = $("#projectJSTree").jstree(true).get_node($(this)).id;

          setSelectedLayer(id);
        }
      });
    }

    if (
      location.pathname === "/vekta-digital/WeatherAnalysis" &&
      platformData.weatherAnalysis.renderERA5
    ) {
      $(".leaflet-era5-grid-pane").show();

      if (layerQuery.layerName !== "") {
        resetQueryLayer();
      }
    }

    if (
      location.pathname === "/vekta-digital/LayerControl" &&
      searchParams.get("controlBtn") === "query"
    ) {
      if (selectedLayer !== null) {
        const base = $("#projectJSTree").jstree("get_checked");

        if (
          $("#projectJSTree").jstree().get_node(selectedLayer).type !== "group"
        ) {
          const formattedTree = [];
          for (let i = 0; i < base.length; i++) {
            var node = $("#projectJSTree").jstree().get_node(base[i]);

            if (node.type !== "group") {
              let url =
                process.env.REACT_APP_GEO_SERVER_URL +
                node.id.split(":")[0] +
                "/wms?";
              if (geowebcache.includes(node.id))
                url = process.env.REACT_APP_GEO_SERVER_URL_GEOWEBCACHE;

              formattedTree.push({
                type: node.type,
                name: node.text,
                id: node.id,
                opacity: 100,
                range: [null, null],
                query: node.id === selectedLayer,
                url: url,
              });
            }
          }

          if (
            platformData.layerControl.activeLayers.find(
              (layer) => layer.id === selectedLayer
            )
          ) {
            if (layerQuery.layerName !== "") {
              console.log("RESET QUERY LAYER - layer is active");
              resetQueryLayer();
            }

            setPlatformData((platformData) => ({
              ...platformData,
              layerControl: {
                ...platformData.layerControl,
                activeLayers: formattedTree,
              },
            }));

            var zIndex = -platformData.layerControl.layerOrder[0].layer.indexOf(
              [selectedLayer][0]
            );
            if ([selectedLayer][0] === "Bathymetry:GLOBAL_Bathymetry") {
              zIndex = -platformData.layerControl.layerOrder[0].layer.length;
            }

            setLayerQuery({
              layerName: selectedLayer,
              layerSource: addQueryLayer(
                [selectedLayer],
                "active-wms",
                zIndex,
                mapRef.current
              ),
            });
          } else {
            if (!base.includes(selectedLayer) && layerQuery.layerName !== "") {
              console.log("RESET QUERY LAYER - not in base");
              resetQueryLayer();
            }
          }
        } else {
          if (!base.includes(selectedLayer) && layerQuery.layerName !== "") {
            console.log("RESET QUERY LAYER - not in base 2");
            resetQueryLayer();
          }
        }
      }

      return;
    } else if (
      location.pathname === "/vekta-digital/LayerControl" &&
      searchParams.get("controlBtn") === "zoom"
    ) {
      if (selectedLayer !== null) {
        const uploaded =
          platformData.uploadedLayers.layerNames.indexOf(selectedLayer);
        if (uploaded === -1) {
          GeoProvider.getLayerBounds(selectedLayer)
            .then((data) => {
              zoomToLayer(data, mapRef.current);
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          const box = bbox(platformData.uploadedLayers.data[uploaded]);
          zoomToLayer(box, mapRef.current);
        }
      }

      return;
    } else if (
      location.pathname === "/vekta-digital/LayerControl" &&
      searchParams.get("controlBtn") === "showSelected"
    ) {
      showSelected();

      return;
    } else if (
      location.pathname === "/vekta-digital/LayerControl" &&
      searchParams.get("controlBtn") === "hideSelected"
    ) {
      hideSelected();

      return;
    } else if (
      location.pathname === "/vekta-digital/LayerControl" ||
      platformData.layerControl.activeLayers.filter((layer) => layer.query)
        .length === 0
    ) {
      if (layerQuery.layerName !== "") {
        resetQueryLayer();
      }

      return;
    }
  }, [location, selectedLayer]);

  /**
   *
   * Add the uploaded shapefile onto the leaflet map
   *
   */
  useEffect(() => {
    if (uploadedData && mapRef.current) {
      addUploadedLayer(
        uploadedData,
        setUploadedData,
        mapRef.current,
        platformData.uploadedLayers
      );
    }
  }, [platformData.uploadedLayers, uploadedData]);

  /**
   *
   * Data Search --> Controls for data search
   *
   */
  useEffect(() => {
    if (mapRef.current) {
      if (searchParams.getAll("dataSearch").length > 0) {
        const values = searchParams.getAll("dataSearch");

        console.log({ values, portData });

        if (values.includes("ais") && aisData.layerName === "") {
          if (aisData.layerName !== "") {
            removeQueryLayer(aisData);
          }

          setAisData({
            layerName: "Sea_Traffic:aisstreamlatest",
            layerSource: addQueryLayer(
              ["Sea_Traffic:aisstreamlatest"],
              "ais-data",
              1,
              mapRef.current
            ),
          });
        } else if (!values.includes("ais") && aisData.layerName !== "") {
          removeQueryLayer(aisData);
          setAisData({ layerName: "", layerSource: {} });
        }

        if (values.includes("port")) {
          if (portData.layerName !== "") {
            removeQueryLayer(portData);
          }

          const filter = formatCqlFilter(platformData.dataSearch.cqlFilter);
          setPortData({
            layerName: "Ports_Marine_Services:GLOBAL_Ports",
            layerSource: addQueryLayer(
              ["Ports_Marine_Services:GLOBAL_Ports"],
              "port-data",
              1,
              mapRef.current,
              filter
            ),
          });
        } else if (!values.includes("port") && portData.layerName !== "") {
          removeQueryLayer(portData);
          setPortData({ layerName: "", layerSource: {} });
        }
      } else if (aisData.layerName !== "") {
        removeQueryLayer(aisData);
        setAisData({ layerName: "", layerSource: {} });
      } else if (portData.layerName !== "") {
        setPortData({ layerName: "", layerSource: {} });
        removeQueryLayer(portData);
      }
    }
  }, [location, platformData.dataSearch.change, mapRef.current]);

  useEffect(() => {
    // Layer options filter is on
    if (
      searchParams.getAll("layeroptions").includes("filter") &&
      platformData.datasearch !== null
    ) {
      console.log("YOU ARE IN LAYER OPTIONS");
      // Create the filter based on the datasearch
      const filter = formatCqlFilter(platformData.datasearch);
      console.log(filter);
      // If filter is not null then display the layer
      if (filter !== null) {
        setFilterData({
          layerName: selectedLayer,
          layerSource: addQueryLayer(
            [selectedLayer],
            "active-wms",
            1,
            mapRef.current,
            filter
          ),
        });
        /// BUG: split is causing crash as it's null?
        console.log(filterData.layerSource);
        // If filter null then set filterData to empty
      } else {
        console.log(filterData.layerSource);

        if (
          typeof filterData.layerSource === "object" &&
          filterData.layerSource !== null &&
          typeof filterData.layerSource.removeSubLayer === "function"
        ) {
          filterData.layerSource.removeSubLayer(selectedLayer);
        } else {
          console.log("not class");
        }
      }
    } else {
    }
  }, [platformData.datasearch]);

  /**
   *
   * Save all points, lines and polygons to platform data for saving
   *
   */
  useEffect(() => {
    if (
      board.points.length > 0 ||
      board.lines.length > 0 ||
      board.polygons.length > 0
    ) {
      setPlatformData({
        ...platformData,
        drawnLayers: {
          points: board.points,
          lines: board.lines,
          polygons: board.polygons,
        },
      });
    }
  }, [board.points, board.lines, board.polygons]);

  const layerParams = useMemo(() => {
    return {
      getLayerID: base64.encode(
        `${process.env.REACT_APP_GEO_SERVER_USERNAME}:${process.env.REACT_APP_GEO_SERVER_PASSWORD}`
      ),
    };
  }, []);

  useEffect(() => {
    setBoard((board) => ({ ...board, ["mode"]: mode }));
  }, [mode]);

  /**
   * Updated the selected weather point if it has changed
   */
  useEffect(() => {
    if (mapRef.current && platformData.weatherAnalysis.pointClick.length > 0) {
      mapRef.current.eachLayer((layer) => {
        // Check if the layer is in the desired pane
        if (layer.options.pane === "era5-point") {
          // Check and add points
          if (layer instanceof L.CircleMarker) {
            layer.remove();
          }
        }
      });

      L.circle(
        [
          platformData.weatherAnalysis.pointClick[1],
          platformData.weatherAnalysis.pointClick[0],
        ],
        { radius: 1500, color: "yellow", pane: "era5-point" }
      )
        .bindTooltip("Selected Weather Point", { className: "latlongtooltip" })
        .openTooltip()
        .addTo(mapRef.current);
    }
  }, [platformData.weatherAnalysis.pointClick, mapRef.current]);

  const tileSize = 256;

  return (
    <>
      <StyledMapContainer cursor={cursor}>
        <MapContainer
          ref={mapRef}
          center={position}
          zoom={3}
          style={{ height: "100vh", width: "100%" }}
          doubleClickZoom={false}
          maxBoundsViscosity={1}
          maxBounds={[
            [-90, -180],
            [90, 180],
          ]}
        >
          <Pane name="point-draw" style={{ zIndex: 499 }}></Pane>
          <Pane name="line-draw" style={{ zIndex: 499 }}></Pane>
          <Pane name="rectangle-draw" style={{ zIndex: 499 }}></Pane>
          <Pane name="uploaded-layer" style={{ zIndex: 490 }}></Pane>
          <Pane name="active-wms" style={{ zIndex: 489 }}></Pane>
          <Pane name="ais-data" style={{ zIndex: 489 }}></Pane>
          <Pane name="port-data" style={{ zIndex: 489 }}></Pane>
          <Pane name="era5-grid" style={{ zIndex: 500 }}></Pane>
          <Pane name="era5-point" style={{ zIndex: 500 }}></Pane>
          <Pane name="site-cables" style={{ zIndex: 502 }}></Pane>
          <Pane name="site-builder" style={{ zIndex: 501 }}></Pane>
          <Pane name="map-name-labels" style={{ zIndex: 699 }}></Pane>
          <Pane name="windfarm-search" style={{ zIndex: 499 }}></Pane>
          {platformData.layerControl.activeLayers.map((val, key) => {
            var zIndex = -platformData.layerControl.layerOrder[0].layer.indexOf(
              val.id
            );
            if (
              val.id === "Bathymetry:GLOBAL_Bathymetry" ||
              val.id === "Bathymetry:GLOBAL_Offshore-Contours"
            ) {
              zIndex = -platformData.layerControl.layerOrder[0].layer.length;
            }

            const url =
              process.env.REACT_APP_GEO_SERVER_URL +
              val.id.split(":")[0] +
              "/wms?";

            return ((val.type === "wms" && !val.query) ||
              (val.type === "legend" && !val.query) ||
              (val.type === "cog" && !val.query)) &&
              !platformData.uploadedLayers.layerNames.includes(val) &&
              url === val.url ? (
              // <WMSLayer layer={val} />
              <WMSTileLayer
                key={val.id}
                pane="active-wms"
                url={"https://" + url}
                layers={val.id}
                className={val.id.split(":")[1] + "_active"}
                params={layerParams}
                format="image/png"
                //checkempty={true}
                transparent
                tileSize={256}
                minZoom={3}
                detectRetina={true}
                maxZoom={18}
                noWrap={true}
                opacity={val.opacity / 100}
                zIndex={zIndex}
              />
            ) : (
              <WMSTileLayerWithHeader
                key={val.id}
                pane="active-wms"
                url={"https://" + val.url}
                layers={val.id}
                className={val.id.split(":")[1] + "_active"}
                params={layerParams}
                format="image/png"
                //checkempty={true}
                transparent
                tileSize={tileSize}
                minZoom={3}
                detectRetina={true}
                maxZoom={18}
                noWrap={true}
                opacity={val.opacity / 100}
                zIndex={zIndex}
                headers={{
                  Authorization: `Basic ${base64.encode(
                    `${process.env.REACT_APP_GEO_SERVER_USERNAME}:${process.env.REACT_APP_GEO_SERVER_PASSWORD}`
                  )}`,
                }}
              />
            );
          })}
          ;
          <Board board={board} setBoard={setBoard} />
          <TileLayer
            minZoom={3}
            maxZoom={18}
            pane="map-name-labels"
            noWrap={true}
            bounds={[
              [-90, -180],
              [90, 180],
            ]}
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
            url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager_only_labels/{z}/{x}/{y}@2x.png"
          />
          <LayersControl>
            <BaseLayer checked name="Base Map (CARTO)">
              <TileLayer
                minZoom={3}
                maxZoom={18}
                noWrap={true}
                detectRetina={true}
                bounds={[
                  [-90, -180],
                  [90, 180],
                ]}
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}@2x.png"
              />
            </BaseLayer>
            <BaseLayer name="Grey World (CARTO)">
              <TileLayer
                minZoom={3}
                maxZoom={18}
                noWrap={true}
                detectRetina={true}
                bounds={[
                  [-90, -180],
                  [90, 180],
                ]}
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
              />
            </BaseLayer>
            <BaseLayer name="OpenStreetMap (OSM)">
              <TileLayer
                minZoom={3}
                maxZoom={18}
                noWrap={true}
                detectRetina={true}
                bounds={[
                  [-90, -180],
                  [90, 180],
                ]}
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
            </BaseLayer>
            <BaseLayer name="Satellite (Esri)">
              <TileLayer
                minZoom={3}
                maxZoom={18}
                noWrap={true}
                detectRetina={true}
                bounds={[
                  [-90, -180],
                  [90, 180],
                ]}
                attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
              />
            </BaseLayer>
            <BaseLayer name="Topographic (OSM)">
              <TileLayer
                minZoom={3}
                maxZoom={18}
                noWrap={true}
                detectRetina={true}
                bounds={[
                  [-90, -180],
                  [90, 180],
                ]}
                attribution='Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
                url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png"
              />
            </BaseLayer>
          </LayersControl>
        </MapContainer>
      </StyledMapContainer>

      <WindfarmSearch map={mapRef.current} />

      <DrawControlPanel setMode={setMode} mode={mode} configRef={configRef} />
    </>
  );
}

export default Map;
