/**
 * 
 * This code is written, owned and maintained by 
 * Vekta Group Energy Division.
 * 
 * © 2023, Vekta Group Energy Division.
 * 
 */

import React, { useEffect, useRef, useState } from 'react'
import Plot from 'react-plotly.js';
import Plotly from "plotly.js-basic-dist-min";

function WindSpeed({ data, month }) {
    const plotSizeX = .27;
    const plotSizeY = .5;

    const plotRef = useRef();
    const [windSpeed, setWindSpeed] = useState([]);

    const layout = {
        title: {
            text: "Monthly Wind<br> Speed Distribution",
            font:{
             family: "Montserrat"
            }
            },
        font: { size: 13, family: "Montserrat"},
        autosize: true,
        legend: { font: { size: 13, family: "Montserrat" } },
        plot_bgcolor: "rgba(0,0,0,0)",
        paper_bgcolor: "rgba(0,0,0,0)",
        xaxis: { autorange: true, dtick: 5, title: "Wind Speed (m/s)",
        ticklen: 5},
        yaxis: { autorange: true, 
                    title: "Probability", 
                        ticklen: 5},
        //width: document.getElementById("weatherAnalysis_widget").style.width,
        height: "500px",
        legend: { orientation: "h", font: { size: 13 , family: "Montserrat"} ,
        x: 0, // Center horizontally (0 is left, 1 is right)
        y: -0.4, // Center vertically (0 is bottom, 1 is top)
    },
        tickangle: -90,
        margin: {
            l: 75, // Adjust the left margin to create space for the legend
            r: 50, // Adjust the right margin to create space for the legend
            t: 75, // Adjust the top margin to create space for the legend
            b: 150, // Adjust the bottom margin to create space for the legend
          }
    }

    const config = {
        responsive: true,
        toImageButtonOptions: {
            format: 'png', // one of png, svg, jpeg, webp
            filename: 'WindSpeedDistribution',
            height: 500,
            width: 700,
            scale: 1 // Multiply title/legend/axis/canvas sizes by this factor
        }
    };

    const bins = [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5, 16, 16.5, 17, 17.5, 18, 18.5, 19, 19.5, 20, 20.5, 21, 21.5, 22, 22.5, 23, 23.5, 24, 24.5, 25, 25.5, 26, 26.5, 27, 27.5, 28, 28.5, 29, 29.5, 30, 30.5, 31, 31.5, 32, 32.5, 33, 33.5, 34, 34.5, 35, 35.5, 36, 36.5, 37, 37.5, 38, 38.5, 39, 39.5, 40]
    const colours = [
        "#AE76A3", "#882E72", "#1965B0", "#5289C7", "#7BAFDE", "#4EB265", "#CAE0AB" ,
        "#E1CC2D", "#F4A736" ,"#E8601C" ,"#DC050C", "#D1BBD7","#521913"
      ];



    const processData = () => {
        const processed = []

        for (let i = 0; i < data.probs.length; i++) {
            processed.push({
                x: bins,
                y: data.probs[i],
                name: month[i],
                type: "scatter",
                hovertemplate: 'Wind Speed: %{x} m/s: <br>Probability: %{y:.4f}', // Customize the tooltip format here
                line: {
                    color: colours[i % colours.length],
                  },
                
         })
        }

        setWindSpeed(processed)
    }

    useEffect(() => {
        console.log(data);
        if (data && data.probs) {
            processData()

            if (plotRef.current) {
                Plotly.Plots.resize(plotRef.current.el)
            }
        }
    }, [data])

    return (
        <div>
            <Plot useResizeHandler style={{ width: "100%", height: "500px" }} className='weatherAnalysis_windSpeed' data={windSpeed} layout={layout} config={config} />
        </div>
    )
}

export default WindSpeed