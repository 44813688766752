/**
 * 
 * This code is written, owned and maintained by 
 * Vekta Group Energy Division.
 * 
 * © 2023, Vekta Group Energy Division.
 * 
 */


import LoginForm from "./vektaDigital/Login";
import LandingPage from "./landing/index";
import Tool from "./vektaDigital/Tool";
import { doc, getDoc } from "firebase/firestore";

import LayerControl from "./vektaDigital/widgets/layerControl/LayerControl";
import WeatherAnalysis from "./vektaDigital/widgets/weatherAnalysis/WeatherAnalysis";
import Yield from "./vektaDigital/widgets/yield/Yield"

import ElevationAnalysis from "./vektaDigital/widgets/elevationAnalysis/ElevationAnalysis";
import DataSearch from "./vektaDigital/widgets/dataSearch/DataSearch";

import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "@firebase/auth";
import { auth, accessList } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { CookiesProvider } from 'react-cookie';

import "primereact/resources/themes/lara-light-cyan/theme.css";

function App() {
  const [authorized, setAuthorized] = useState(true);
  const [page, setPage] = useState("home");
  const [user, loading, error] = useAuthState(auth);

  // Check if authenticated user is authorized
  useEffect(() => {
    if (user) {
      const checkAuthorization = async () => {
        if (accessList.exists()) {
          const authorizedUsers = accessList.data().userIDs;
          if (authorizedUsers.includes(user.uid)) {
            setAuthorized(true);
          } else {
            setAuthorized(false);
          }
        } else {
          console.log("No such document!");
        }
      };

      // checkAuthorization();
    }
  }, [user]);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--scaleFactor",
      window.devicePixelRatio
    );
  }, []);

  return (
    <CookiesProvider>
      <Router>
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          {/* {authorized ? ( */}
          <Route exact path="vekta-digital" element={<Tool />}>
            <Route exect path="LayerControl" element={<LayerControl />} />
            <Route exect path="WeatherAnalysis" element={<WeatherAnalysis />} />
            <Route exect path="ElevationAnalysis" element={<ElevationAnalysis />} />
            <Route exect path="DataSearch" element={<DataSearch />} />
            <Route exect path="Yield" element={<Yield />} />
          </Route>
          {/* ) : (
            <Route exact path="vekta-digital" element={<AccessDenined />}>
              <Route exect path="LayerControl" element={<AccessDenined />} />
              <Route exect path="WeatherAnalysis" element={<AccessDenined />} />
              <Route exect path="ElevationAnalysis" element={<AccessDenined />} />
              <Route exect path="DataSearch" element={<AccessDenined />} />
            </Route>
          )
          } */}
        </Routes>
      </Router>
    </CookiesProvider>
  );
}

export default App;
