/**
 * 
 * This code is written, owned and maintained by 
 * Vekta Group Energy Division.
 * 
 * © 2023, Vekta Group Energy Division.
 * 
 */

import React, { useContext, useEffect, useState } from 'react'
import styled from "styled-components";
import { Typography, Button } from "@mui/material";

import L from "leaflet";
import spinnerIcon from "../../media/turbineLoading.gif"
import { v4 as uuidv4, validate } from "uuid";

import {
    highlightSelected,
    getSelectedLatLngs,
    extractCentroid
} from "./drawFunctions";



const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5px;
  font-size: 1.2rem;


  .input {
    padding-bottom: 5px;
    font-family: 'Montserrat';
    input {
        padding-left: 5px;
    }  

    select {
        padding-left: 5px;
    }  
}

`;

const Title = styled(Typography)`
  color: #00A0C6;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding-bottom: 8px;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 5px;
  flex-grow: 1;
  justify-content: flex-start; // Align the text and icon to the left
`;

const Divider = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: -5px;
`;

const FlexCenter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

function SiteBuilderMenu({ coords, popup, configRef, callFrom, layer = null }) {
    const [missingInfo, setMissingInfo] = useState(false);
    const setPlatformData = configRef.current.setPlatformData;

    const [siteOptions, setSiteOptions] = useState({
        "siteName": "Demo Site",
        "alignment": "Center",
        "fillType": "palceAtPoint",
        "angle": null,
        "minDist": 1200,
        "form": "Square",
        "nrows": 5,
        "ncols": 5,
        "nturbs": 25,
        "buffM": 1
    })
    const apiHeaders = {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept, Authorization",
        "Access-Control-Request-Method": "GET, POST, DELETE, PUT, OPTIONS",
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };

    useEffect(() => {
        if (layer) {
            configRef.current.selected = layer._leaflet_id;
            highlightSelected(configRef);
        }
    }, []);

    const handleSiteCreation = async () => {

        setMissingInfo(false)

        const dim = 100;
        const newIcon = L.icon({
            iconUrl: spinnerIcon,
            iconSize: [dim * 2, dim],
            iconAnchor: [dim, dim / 4],
        });

        const spinner = L.marker(coords, {
            icon: newIcon
        }).addTo(configRef.current.map)

        const getDominantWind = async (weatherJson) => {
            try {
                const response = await fetch(
                    "https://vekdig-hiibq2h6dq-nw.a.run.app/weather",
                    {
                        method: "POST",
                        headers: apiHeaders,
                        body: JSON.stringify(weatherJson),
                    }
                );

                return await response.json()
            } catch (err) {
                console.log(err)
                alert("Sorry! Something went wrong, please try again later")
                spinner.remove();
                return
            }
        }

        const weatherJson = {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    coords.lng,
                    coords.lat
                ]
            },
            "properties": {
                "name": "Sample Point",
                "dataloc": "gcp",
                "weatherdatatype": "vektawindinput",
                "windheight": [100]
            }
        }

        var angle = siteOptions.angle
        console.log(angle);
        if (siteOptions.angle === null || isNaN(siteOptions.angle)) {
            console.log(weatherJson);
            angle = await getDominantWind(weatherJson)
            console.log(angle);
            angle = (angle.domWD[0] + 180) % 360
        }

        var siteInfoJson;
        if (callFrom === "Board") {
            var totalTurbs = siteOptions.nrows * siteOptions.ncols
            //if (totalTurbs !== siteOptions.nturbs) totalTurbs = siteOptions.nturbs

            siteInfoJson = {
                "alignment": [siteOptions.alignment],
                "angle": [angle],
                "minDist": [siteOptions.minDist],
                "form": [siteOptions.form],
                "nturbs": [totalTurbs],
                "nrows": [siteOptions.nrows],
                "ncols": [siteOptions.ncols],
                "coord": [
                    { "lon": coords.lng, "lat": coords.lat }
                ]
            }
        } else {
            const formattedCoords = []
            const layerCoords = getSelectedLatLngs(configRef.current)[0]
            //layerCoords.push(layerCoords[0])
            layerCoords.forEach(latlng => {
                formattedCoords.push({ "lon": latlng.lng, "lat": latlng.lat })
            });

            if (siteOptions.fillType === "placeInPolygon") {
                var totalTurbs = siteOptions.nrows * siteOptions.ncols
                //if (totalTurbs !== siteOptions.nturbs) totalTurbs = siteOptions.nturbs

                siteInfoJson = {
                    "alignment": [siteOptions.alignment],
                    "angle": [angle],
                    "minDist": [siteOptions.minDist],
                    "buffM": [siteOptions.buffM],
                    "form": [siteOptions.form],
                    "nturbs": [totalTurbs],
                    "accRows": [siteOptions.nrows],
                    "accCols": [siteOptions.ncols],
                    "coord": [...formattedCoords, formattedCoords[0]],
                    "pointIn": [{ "lon": coords.lng, "lat": coords.lat }]
                }
            } else if (siteOptions.fillType === "fillPolygon") {
                var totalTurbs = siteOptions.nrows * siteOptions.ncols
                //if (totalTurbs !== siteOptions.nturbs) totalTurbs = siteOptions.nturbs

                siteInfoJson = {
                    "angle": [angle],
                    "minDist": [siteOptions.minDist],
                    "buffM": [siteOptions.buffM],
                    "form": [siteOptions.form],
                    "nturbs": [totalTurbs],
                    "coord": formattedCoords,
                }
            } else {
                setMissingInfo(true)
                return;
            }
        }

        const getTurbPlacement = async () => {
            console.log(siteInfoJson);
            try {
                const response = await fetch(
                    `https://vekwin-hiibq2h6dq-ez.a.run.app/${siteOptions.fillType}`,
                    {
                        method: "POST",
                        headers: apiHeaders,
                        body: JSON.stringify(siteInfoJson),
                    }
                ).catch((error) => {
                    console.log(error);
                });

                return await response.json()
            } catch (err) {
                console.log(err)
                // alert("Sorry! Something went wrong, please try again later")
                spinner.remove();
                return
            }
        }

        const turbs = await getTurbPlacement();
        console.log(turbs)
        spinner.remove();

        if (turbs?.error) {
            window.alert("Error while creating site - Please try again")
        } else {
            if (turbs.length > 0) {
                // setTurbLocations(turbs)
                setPlatformData(platformData => ({
                    ...platformData,
                    "siteBuilder": {
                        ...platformData.siteBuilder,
                        [uuidv4()]: {
                            "siteTurbs": turbs,
                            "siteName": siteOptions.siteName,
                            "siteSettings": siteOptions,
                            "siteOSP": [],
                            "siteCables": [],
                            "datetime": Date()
                        }
                    }
                }))
            }
        }
    
    
    }

    const validateInput = (value, min, max, type) => {
        // If value is an empty string, return empty string
        if (value === '') {
            return '';
        }
    
        if (!(value instanceof type)) {
            if (value < min) {
                return type(min);
            } else if (max && value > max) {
                return type(max);
            }
            return type(value);
        }
        return value;
    };
    

    return (
        <Container className='siteBuilder_options'>
            <Title variant="h7" component="h2" align="center">
                SITE BUILDER OPTIONS
            </Title>

            <div className='input'>
                <label>Site Name</label>
                <input type='text' placeholder='Demo Site' onChange={(e) => setSiteOptions(siteOptions => ({
                    ...siteOptions,
                    "siteName": e.target.value
                }))} />
            </div>

            <FlexCenter>
                {callFrom === "API" &&
                    <div className='input'>
                        <label>Placement Type</label>
                        <select className={missingInfo && "toggle_missingInfoShake toggle_missingInfo"} onChange={(e) => {
                            setMissingInfo(false)
                            setSiteOptions(siteOptions => ({
                                ...siteOptions,
                                "fillType": e.target.value
                            }))
                        }}>
                            <option value={"palceAtPoint"}>Select Option...</option>
                            <option value={"placeInPolygon"}>Placement in Boundary</option>
                            <option value={"fillPolygon"}>Fill Shape</option>
                        </select>
                    </div>
                }
                <div className='input'>
                    <label>Grid Format</label>
                    <select onChange={(e) => {

                        if (e.target.value !== "prevailing") {
                            setSiteOptions(siteOptions => ({
                                ...siteOptions,
                                "form": e.target.value
                            }))
                        } else {
                            setSiteOptions(siteOptions => ({
                                ...siteOptions,
                                "angle": "prevailing"
                            }))
                        }
                    }}>
                        <option>Square</option>
                        <option>Hex</option>
                    </select>
                </div>
            </FlexCenter>

            {(siteOptions.fillType === "palceAtPoint" || siteOptions.fillType === "placeInPolygon") &&
                <FlexCenter>
                    <div className='input'>
                        <label>Placement Alignment</label>
                        <select onChange={(e) => setSiteOptions(siteOptions => ({
                            ...siteOptions,
                            "alignment": e.target.value
                        }))}>
                            <option value={"Center"}>Center of Click/Shape</option>
                            <option value={"Point"}>Point of Click</option>
                            <option value={"South-West"}>Point of Click is South West</option>
                            <option value={"South-East"}>Point of Click is South East</option>
                            <option value={"North-West"}>Point of Click is North West</option>
                            <option value={"North-East"}>Point of Click is North East</option>
                        </select>
                    </div>

                    <div className='input'>
                        <label>Number of Rows</label>
                        <input type='number' min={'1'} placeholder='e.g. 5' onChange={(e) => {
                            e.target.value = validateInput(e.target.value, "", null, Number)
                            setSiteOptions(siteOptions => ({
                                ...siteOptions,
                                "nrows": parseInt(e.target.value)
                            }))
                        }} />
                    </div>

                    <div className='input'>
                        <label>Number of Columns</label>
                        <input type='number' min={'1'} placeholder='e.g. 5' onChange={(e) => {
                            e.target.value = validateInput(e.target.value, "", null, Number)
                            setSiteOptions(siteOptions => ({
                                ...siteOptions,
                                "ncols": parseInt(e.target.value)
                            }))
                        }} />
                    </div>
                </FlexCenter>
            }

            <FlexCenter>
                {(siteOptions.fillType === "palceAtPoint" || siteOptions.fillType === "placeInPolygon") &&
                    <div className='input'>
                        <label>Total of Turbines</label>
                        <input type='number' 
                        min='1'
                        value={siteOptions.nrows * siteOptions.ncols} 
                        placeholder='e.g. 25' 
                        onChange={(e) => setSiteOptions(siteOptions => ({
                            ...siteOptions,
                            "nturbs": parseInt(e.target.value)
                        }))} />
                    </div>
                }

                <div className='input'>
                    <label>Turbines Spacing (m)</label>
                    <input type='number' placeholder='e.g. 1200' onChange={(e) => {
                        e.target.value = validateInput(e.target.value, "", null, Number)
                        setSiteOptions(siteOptions => ({
                            ...siteOptions,
                            "minDist": parseInt(e.target.value)
                        }))
                    }} />
                </div>

                <div className='input'>
                    <label>Site Inset Buffer (m)</label>
                    <input type='number' placeholder='e.g. 2500' onChange={(e) => {
                        e.target.value = validateInput(e.target.value, "", null, Number)
                        setSiteOptions(siteOptions => ({
                            ...siteOptions,
                            "buffM": parseInt(e.target.value)
                        }))
                    }} />
                </div>

                <div className='input'>
                    <label>Turbines Angle (&deg;) <a title='Prevailing wind direction will be used if left empty'>?</a></label>
                    <input type='number' min={0} max={360} placeholder='Prevailing Wind' onChange={(e) => {
                        e.target.value = validateInput(e.target.value, "", 360, Number)
                        setSiteOptions(siteOptions => ({
                            ...siteOptions,
                            "angle": parseInt(e.target.value)
                        }))
                    }} />
                </div>
            </FlexCenter>

            <Divider />

            <Button variant="text"
                size="medium"
                style={{
                    flexGrow: 1,
                    fontSize: '14px', // Adjust the font size for a larger button
                    color: '#00A0C6', // Add this line to change the text color
                }}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();

                    if (callFrom !== "Board") {
                        if (siteOptions.fillType !== "palceAtPoint") {
                            handleSiteCreation();
                            popup.remove();
                        } else {
                            setMissingInfo(true);
                        }
                    } else {
                        handleSiteCreation();
                        popup.remove();
                    }
                }}
            >
                Create Site
            </Button>
        </Container>
    )
}

export default SiteBuilderMenu