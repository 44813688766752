import React, { useEffect, useState } from 'react'
import styled from "styled-components";
import { getPurchasedTools, getSharingGroup, getSubUsers, getUserProfile } from '../helperFunctions';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db, compnayRegister } from "../../../../firebase";
import DataTable from 'react-data-table-component';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';

const Container = styled.div`
    position: absolute;
    inset: 40px 40px 40px 50%;
    background: white;
    padding: 24px;
    transition: ease-in 2s;
    border-radius: 8px;
    box-shadow: 0px 0px 20px #a4a4a4;
`;

function UserProfile({ setSidePanel, subUrl, purchased, subUsers }) {
    const [user, loading, error] = useAuthState(auth);

    const [yourInfo, setYourInfo] = useState({});
    const [editInfo, setEditInfo] = useState(true)

    const [shareGroup, setShareGroup] = useState([])
    const [userAccount, setUserAccount] = useState({ file: null, fileData: {} })

    const sharedTableColumns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Company',
            selector: row => row.company,
            sortable: true,
        },
        {
            name: 'Actions',
            selector: row => row.actions,
            width: "180px"
        },
    ]

    useEffect(() => {
        getUserProfile(user).then((data) => {
            setYourInfo(data)
        })
    }, [])

    useEffect(() => {
        if (user && !user.isAnonymous) {
            try {
                const account = onSnapshot(doc(db, "userAccounts", user.uid), (foundAccount) => {
                    setUserAccount({ file: foundAccount, fileData: foundAccount.data() })
                });
            } catch (e) {
                console.error("Error adding document: ", e);
            }
        }
    }, [user])

    useEffect(() => {
        if (userAccount.file) {
            Object.keys(compnayRegister.data()).map((key) => {
                if (key === userAccount.fileData.company_univeristy) {
                    getSharingGroup(compnayRegister.data()[key]).then(async (data) => {
                        const shareUsers = []
                        for (let i = 0; i < data.data().users.length; i++) {
                            const users = await getDoc(data.data().users[i])

                            console.log(users.id);
                            shareUsers.push({
                                id: user.id,
                                name: users.data().firstname && users.data().lastname ? (users.data().firstname + " " + users.data().lastname) : "Name Missing",
                                email: user.email,
                                company: users.data().company_univeristy,
                                actions: [
                                    <button title="Remove Person" className="removePerson_action">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="15px" viewBox="0 0 448 512">
                                            {/* <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --> */}
                                            <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
                                        </svg>
                                    </button>,],
                            })
                        }

                        setShareGroup(shareUsers)
                    })
                }
            })
        }
    }, [userAccount])

    return (
        <Container>
            <div className='sidePanel_heading'>
                <h3>Welcome - {yourInfo && yourInfo.firstname}</h3>
                <button onClick={() => setSidePanel({ open: false, type: null })}>X</button>
            </div>

            <div className='sidePanel_body'>
                <div className='sidePanel_yourInfo'>
                    <div className='yourInfo_heading'>
                        <h4>Your Info</h4>
                        <button onClick={() => setEditInfo(false)}>Edit</button>
                    </div>

                    <div className='yourInfo_body'>
                        <div className='input'>
                            <label>Firstname *</label>
                            <input type='text' disabled={editInfo} value={yourInfo && yourInfo.firstname} />
                        </div>
                        <div className='input'>
                            <label>Surname *</label>
                            <input type='text' disabled={editInfo} value={yourInfo && yourInfo.lastname} />
                        </div>
                        <div className='input'>
                            <label>Email *</label>
                            <input type='email' disabled={editInfo} value={yourInfo && yourInfo.email} />
                        </div>
                        <div className='input'>
                            <label>Company/University *</label>
                            <input type='email' disabled={editInfo} value={yourInfo && yourInfo.company_univeristy} />
                        </div>
                    </div>

                    <button style={editInfo ? { display: "none" } : {}}>Save</button>

                    <a href={subUrl} target='_blank'>Manage Subscriptions</a>
                </div>

                {/* <div className='sidePanel_subscriptionManager'>
                    <h4>Subscription Manager</h4>

                    <p>{subUrl}</p>
                    <iframe src={subUrl}></iframe>

                    <div className='subscriptionManager_body'>
                        <TableContainer component={Paper} style={{ width: "85%" }}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: 600, fontSize: "15px" }}>Tool Name</TableCell>
                                        <TableCell style={{ fontWeight: 600, fontSize: "15px" }}>Cost</TableCell>
                                        <TableCell style={{ fontWeight: 600, fontSize: "15px" }}>Invoiced</TableCell>
                                        <TableCell style={{ fontWeight: 600, fontSize: "15px" }}>Purchased On</TableCell>
                                        <TableCell style={{ fontWeight: 600, fontSize: "15px" }}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {purchased && Object.keys(purchased).map((id) => {
                                        return id !== null ? (
                                            <TableRow key={id}>
                                                <TableCell style={{ fontSize: "13px" }}>{purchased[id].name}</TableCell>
                                                <TableCell style={{ fontSize: "13px" }}>{purchased[id].price}</TableCell>
                                                <TableCell style={{ fontSize: "13px" }}>True</TableCell>
                                                <TableCell style={{ fontSize: "13px" }}>Today</TableCell>
                                                <TableCell style={{ fontSize: "13px" }}><button>edit</button></TableCell>
                                            </TableRow>
                                        ) : null
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div> */}

                <div className='sidePanel_otherUsers'>
                    <DataTable
                        title="Sharing Group"
                        columns={sharedTableColumns}
                        data={shareGroup}
                        pagination
                    />
                    {/* <TableContainer component={Paper} style={{ width: "85%" }}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: 600, fontSize: "15px" }}>Users Name</TableCell>
                                        <TableCell style={{ fontWeight: 600, fontSize: "15px" }}>Email</TableCell>
                                        <TableCell style={{ fontWeight: 600, fontSize: "15px" }}>Admin Level</TableCell>
                                        <TableCell style={{ fontWeight: 600, fontSize: "15px" }}>Access To</TableCell>
                                        <TableCell style={{ fontWeight: 600, fontSize: "15px" }}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {subUsers && Object.keys(subUsers).map((id) => {
                                        return id !== null ? (
                                            <TableRow>
                                                <TableCell style={{ fontSize: "13px" }}>{subUsers[id].firstname} {subUsers[id].lastname}</TableCell>
                                                <TableCell style={{ fontSize: "13px" }}>{subUsers[id].email}</TableCell>
                                                <TableCell style={{ fontSize: "13px" }}>
                                                    <select value={subUsers[id].admin_level}>
                                                        <option value={32}>Admin</option>
                                                        <option value={3.14}>Basic User</option>
                                                    </select>
                                                </TableCell>
                                                <TableCell style={{ fontSize: "13px" }}>
                                                    <select>
                                                        <option>Test</option>
                                                    </select>
                                                </TableCell>
                                                <TableCell style={{ fontSize: "13px" }}><button>edit</button></TableCell>
                                            </TableRow>
                                        ) : null
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer> */}
                </div>
            </div>

            <button>Delete Account</button>
            <button onClick={() => setSidePanel({ open: false, type: null })}>Close</button>
        </Container>
    )
}

export default UserProfile