import React, { useEffect, useRef, useState } from 'react'
import Plot from 'react-plotly.js';
import Plotly from "plotly.js-basic-dist-min";

function CapacityFactorSingleLine({ cfvalues, months }) {
    const plotSizeX = .27;
    const plotSizeY = .5;

    const plotRef = useRef();
    const [capacity, setCapacity] = useState([]);


    const processed = (cfvalues, months) => ({
        x: months,
        y: cfvalues,
        type: "bar",
        name: "Value",
        line: {
            color: "#00A0C6",
            width: 2,
        },
        marker: {
            symbol: "circle",
            size: 8,
            color: "#00A0C6",
        },
    });
    
    // Example usage:
    const data = processed(cfvalues, months);

    console.log(data)

    const layout = {
        title: {
            text: "Capacity Factor by Month",
            font: {
                family: "Montserrat"
            }
        },
        font: { size: 13, family: "Montserrat" },
        autosize: true,
        legend: { font: { size: 13, family: "Montserrat" } },
        plot_bgcolor: "rgba(0,0,0,0)",
        paper_bgcolor: "rgba(0,0,0,0)",
        xaxis: {
         title: "Month",
         tickangle: 45,
         gridcolor: "#EBECEC"
          },
        yaxis: {
            title: "Capacity Factor",
            range: [0, 1], // Set the range from 0 to 1
            gridcolor: "#EBECEC"
        },
        //width: document.getElementById("weatherAnalysis_widget").style.width,
        height: "800px",
        legend: {
            orientation: "h", font: { size: 13, family: "Montserrat" },
            x: 0, // Center horizontally (0 is left, 1 is right)
            y: -0.4, // Center vertically (0 is bottom, 1 is top)
        },
        tickangle: -90,
        margin: {
            l: 75, // Adjust the left margin to create space for the legend
            r: 50, // Adjust the right margin to create space for the legend
            t: 75, // Adjust the top margin to create space for the legend
            b: 50, // Adjust the bottom margin to create space for the legend
        }
    }

    const config = {
        modeBarButtonsToRemove: ['pan2d','select2d','lasso2d','resetScale2d','zoomOut2d', 'autoScale2d','zoomIn2d', 'zoom2d'],
        responsive: true,
        toImageButtonOptions: {
            format: 'png', // one of png, svg, jpeg, webp
            filename: 'CFbymonth',
            height: 500,
            width: 700,
            scale: 1 // Multiply title/legend/axis/canvas sizes by this factor
        }
    };

useEffect(()=>{

    console.log(capacity)

},[capacity])


    return (
        <div>
        <Plot
            useResizeHandler
            style={{ width: "100%", height: "500px" }}
            className='weatherAnalysis_windSpeed'
            data={[data]}
            layout={layout}
            config={config}
        />       
         </div>
    )
}

export default CapacityFactorSingleLine;
