/**
 *
 * This code is written, owned and maintained by
 * Vekta Group Energy Division.
 *
 * © 2023, Vekta Group Energy Division.
 *
 */

import React, { useContext, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  useNavigate,
  useLocation,
  useSearchParams,
  redirect,
} from "react-router-dom";
// import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableFooter, TablePagination } from '@mui/material';
import DataTable from "react-data-table-component";

import WidgetButton from "./WidgetButton";
import { auth, db, logout, storage, analytics, app } from "../../firebase";
// import { ref, uploadBytes } from "firebase/storage";
import {
  doc,
  setDoc,
  onSnapshot,
  getDoc,
  updateDoc,
  deleteField,
} from "firebase/firestore";
import Modal from "react-modal";

import vektaGroup from "../../media/VG_Logo_WhiteVector.png";
import { StoreWidgets } from "../../imports/Context";
import { PlatformData } from "../../imports/Context";
import StorePopup from "./vektaStore/StorePopup";

import styled from "styled-components";

import { logEvent } from "firebase/analytics";

import { InputTextarea } from "primereact/inputtextarea";
import { Message } from "primereact/message";
import { Box, Typography, Button, TextField, Grid } from "@mui/material";
import { getFunctions, httpsCallable } from "firebase/functions";

import { BounceLoader } from "react-spinners";

import { ref, getDownloadURL } from "firebase/storage";

const ReplayTour = styled.button`
  margin-right: 25px;
  border: none;
  padding: 16px;
  border-radius: 8px;
  height: 100%;

  &:hover {
    color: white;
    background: #007592;

    img,
    svg {
      filter: invert(1);
    }
  }
`;

function Sidebar({
  sidebarContent,
  setSidebarContent,
  setRunJoyride,
  setRunJoyrideSteps,
  steps,
  stepsMap,
  platformStructure,
}) {
  const [isGreyedOut, setIsGreyedOut] = useState(false);
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const { platformData, setPlatformData } = useContext(PlatformData);
  const [currentWidget, setCurrentWidget] = useState();
  const widgets = useContext(StoreWidgets);

  const [saveLoad, setSaveLoad] = useState({
    open: false,
    currentSelect: "save",
  });
  const [customerService, setCustomerService] = useState(false);
  const [isSaving, setIsSaving] = useState("initial");

  const [store, setStore] = useState({
    open: false,
    currentSelect: "allTools",
  });
  const [vektaStore, setVektaStore] = useState();

  const [dot, setDot] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();
  const [openedProject, setOpenedProject] = useState(null);

  const [userSaves, setUserSaves] = useState({
    file: null,
    fileData: { personal: {}, shared: {} },
  });
  const [userAccount, setUserAccount] = useState({ file: null, fileData: {} });

  const [savedProjects, setSavedProjects] = useState([]);
  const [deletePop, setDeletePop] = useState({
    open: false,
    key: null,
    type: null,
  });

  const navigate = useNavigate();
  const location = useLocation();

  const [comment, setComment] = useState("");
  const [messageSent, setMessageSent] = useState(false);
  const [messageLoading, setMessageLoading] = useState(false);

  const handleAddComment = () => {
    const functions = getFunctions(app, "us-central1");
    const addComment = httpsCallable(functions, "addComment");
    setMessageLoading(true);

    console.log(comment);
    addComment({ comment: comment })
      .then((result) => {
        // Read result of the Cloud Function.
        console.log(result.data);
        setMessageSent(result.data.success);
        setComment("");
        setMessageLoading(!result.data.success);
        // handleCloseModal(); // Close modal on successful comment submission
      })
      .catch((error) => {
        // Getting the Error details.
        setMessageSent(false);
        console.log(error.code);
        console.log(error.message);
        console.log(error.details);
      });
  };

  const handleMetaDownload = () => {
    // Get the download URL
    getDownloadURL(ref(storage, "appMisc/layerDataReference.xlsx"))
      .then((url) => {
        // Insert url into an <img> tag to "download"
        const a = document.createElement("a");

        a.href = url;
        a.download = "LayerSources.xlsx";
        a.target = "_blank";
        a.click();
      })
      .catch((error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/object-not-found":
            // File doesn't exist
            break;
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;
          case "storage/canceled":
            // User canceled the upload
            break;

          // ...

          case "storage/unknown":
            // Unknown error occurred, inspect the server response
            break;
        }
      });
  };

  const savedTableColumns = [
    {
      name: "Project Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Last Updated",
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => row.actions,
      width: "180px",
    },
  ];

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");

    if (isSaving !== "initial") {
      setIsSaving("saved/loaded");
    }
  }, [user, loading, platformData, isSaving]);

  useEffect(() => {
    if (platformData.sidePanelOpen) {
      setSidebarContent(true);
    } else {
      setSidebarContent(false);
    }
  }, [platformData.sidePanelOpen]);

  useEffect(() => {
    if (location.pathname !== "/vekta_digital") {
      setDot(".");
    } else {
      setDot("");
    }
  }, [location]);

  useEffect(() => {
    if (user && !user.isAnonymous) {
      try {
        const saves = onSnapshot(doc(db, "userSaves", user.uid), (file) => {
          setUserSaves({ file: file, fileData: file.data() });
        });

        const account = onSnapshot(
          doc(db, "userAccounts", user.uid),
          (foundAccount) => {
            setUserAccount({
              file: foundAccount,
              fileData: foundAccount.data(),
            });
          }
        );
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    }
  }, [user]);

  useEffect(() => {
    if (userSaves.fileData !== undefined) {
      setSavedProjects(
        Object.keys(userSaves.fileData.personal).map((key, i) => {
          return {
            id: i,
            name: key,
            date: userSaves.fileData.personal[key].lastSaved,
            actions: [
              <button
                title="Delete Project"
                onClick={() =>
                  setDeletePop({ open: true, key: key, type: "personal" })
                }
                className="saveloadshare_action"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="15px"
                  viewBox="0 0 448 512"
                >
                  {/* <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --> */}
                  <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
                </svg>
              </button>,
              <button
                title="Load Project"
                onClick={() => handleLoad(key, "personal")}
                className="saveloadshare_action"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="15px"
                  viewBox="0 0 576 512"
                >
                  {/* <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --> */}
                  <path d="M384 480h48c11.4 0 21.9-6 27.6-15.9l112-192c5.8-9.9 5.8-22.1 .1-32.1S555.5 224 544 224H144c-11.4 0-21.9 6-27.6 15.9L48 357.1V96c0-8.8 7.2-16 16-16H181.5c4.2 0 8.3 1.7 11.3 4.7l26.5 26.5c21 21 49.5 32.8 79.2 32.8H416c8.8 0 16 7.2 16 16v32h48V160c0-35.3-28.7-64-64-64H298.5c-17 0-33.3-6.7-45.3-18.7L226.7 50.7c-12-12-28.3-18.7-45.3-18.7H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H87.7 384z" />
                </svg>
              </button>,
            ],
          };
        })
      );
    }
  }, [userSaves.fileData]);

  const handleSave = async () => {
    try {
      if (!user.isAnonymous) {
        document.getElementById("saveLoadBanner").innerHTML =
          "Project Saved Successfully";
        setIsSaving("saving");

        let new_data = {};
        const docRef = doc(db, "userSaves", user.uid);
        const filename = document.getElementById("projectSaveName").value;

        var date = new Date();
        const dateTime =
          date.getDate() +
          "/" +
          (date.getMonth() + 1) +
          "/" +
          date.getFullYear() +
          " @ " +
          date.getHours() +
          ":" +
          date.getMinutes() +
          ":" +
          date.getSeconds();

        new_data = {
          personal: {
            ...userSaves.fileData.personal,
            [filename]: {
              save: JSON.stringify(platformData),
              lastSaved: dateTime,
            },
          },
        };

        await setDoc(docRef, new_data, { merge: true });

        const param = searchParams.get("projectName");

        if (param) {
          searchParams.delete("projectName");
          setSearchParams(searchParams);
        }
      }

      /**
       *
       * GOOGLE ANALYTICS
       *
       */
      logEvent(analytics, "select_content", {
        content_type: "Save project",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleLoad = (projectLoad, type) => {
    try {
      if (!user.isAnonymous) {
        document.getElementById("saveLoadBanner").innerHTML =
          "Project Loaded Successfully";
        setIsSaving("loading");

        // const projectLoad = document.getElementById("projectLoadName").value
        setSearchParams({ ...searchParams, projectName: projectLoad });
        setOpenedProject(projectLoad);
        document.getElementById("projectSaveName").value = projectLoad;
        setPlatformData(JSON.parse(userSaves.fileData[type][projectLoad].save));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (projectDelete, type) => {
    try {
      if (!user.isAnonymous) {
        document.getElementById("saveLoadBanner").innerHTML =
          "Project Deleted Successfully";
        setIsSaving("loading");

        const docRef = doc(db, "userSaves", user.uid);

        const temp = userSaves.fileData[type];
        delete temp[projectDelete];

        await updateDoc(docRef, {
          ...userSaves.fileData,
          [type]: temp,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleLogout = () => {
    setIsGreyedOut(true);

    setPlatformData(platformStructure);
    logout();
  };

  return (
    <div className="sidebarButtons">
      <div className="widgetBtns">
        {widgets.map((val, key) => {
          return val !== 0 ? (
            <WidgetButton
              dot={dot}
              name={val.name}
              svg={val.svg}
              title={val.title}
              currentWidget={platformData.activeWidget}
              sidebarContent={sidebarContent}
              setSidebarContent={setSidebarContent}
            />
          ) : null;
        })}
      </div>

      <div className="settingsBtns">
        {user && !user.isAnonymous ? (
          <button
            title="Coming Soon - Vekta Store"
            disabled
            style={{ opacity: "0.6" }}
            onClick={() => setStore({ ...store, open: true })}
          >
            <img src={dot + "./svg/store-solid.svg"} />
          </button>
        ) : null}

        {user && !user.isAnonymous ? (
          <button
            title="Save/Load"
            disabled={
              platformData.layerControl.layerOrder[0].layer.length === 0
            }
            onClick={() => setSaveLoad({ ...saveLoad, open: true })}
          >
            <img src={dot + "./svg/saveLoad.svg"} />
          </button>
        ) : null}
        <button title="Exit" onClick={handleLogout}>
          <img src={dot + "./svg/landingPage.svg"} />
        </button>
        {isGreyedOut && (
          <div className="grey-screen-overlay">
            <div className="loggingout-text">Logging Out...</div>
          </div>
        )}

        <button title="Help" onClick={() => setCustomerService(true)}>
          <img src={dot + "./svg/help.svg"} />
        </button>
      </div>

      <Modal
        className="saveLoad_popup"
        isOpen={saveLoad.open}
        onRequestClose={() => saveLoad.open}
      >
        <span
          id="saveLoadBanner"
          className={
            isSaving !== "saved/loaded"
              ? "saveLoad_span"
              : "saveLoad_span toggle_display"
          }
        >
          Project Saved Successfully
        </span>
        <div className="popup_heading">
          <button
            className="popup_close"
            onClick={() => {
              setSaveLoad({ ...saveLoad, open: false });
              setIsSaving("initial");
            }}
          >
            X
          </button>
        </div>

        <div className="popup_body">
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <div className="saveCurrent_project">
              <div className="input">
                <label>Project Name</label>
                <input
                  value={openedProject && openedProject}
                  id="projectSaveName"
                  type="text"
                  placeholder="Project Name"
                />
              </div>
              <ReplayTour style={{ marginLeft: "8px" }} onClick={handleSave}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="16"
                  width="14"
                  viewBox="0 0 448 512"
                >
                  {/* <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--> */}
                  <path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V173.3c0-17-6.7-33.3-18.7-45.3L352 50.7C340 38.7 323.7 32 306.7 32H64zm0 96c0-17.7 14.3-32 32-32H288c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V128zM224 288a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                </svg>
              </ReplayTour>
            </div>
          </div>

          <div>
            <DataTable
              title="Personal Projects"
              columns={savedTableColumns}
              data={savedProjects}
              pagination
            />
          </div>
        </div>

        <div className="popup_footer">
          <footer>
            <button
              onClick={() => {
                setSaveLoad({ ...saveLoad, open: false });
                setIsSaving("initial");
              }}
            >
              Done
            </button>
          </footer>
        </div>
      </Modal>

      <Modal
        className="shareWith_internalPopup"
        isOpen={deletePop.open}
        onRequestClose={() => deletePop.open}
      >
        <div className="popup_heading">
          <h3>Are You Sure?</h3>
          <button
            className="popup_close"
            onClick={() => {
              setDeletePop({ open: false, key: null });
            }}
          >
            X
          </button>
        </div>

        <p>
          You are about to delete a project! Onced delete this project cannot be
          recovered.
        </p>

        <footer className="popup_footer">
          <button
            className="cancel_button"
            onClick={() => {
              setDeletePop({ open: false, key: null });
            }}
          >
            Cancel
          </button>
          <button
            className="share_button"
            onClick={() => {
              handleDelete(deletePop.key, deletePop.type);
              setDeletePop({ open: false, key: null });
            }}
          >
            Delete
          </button>
        </footer>
      </Modal>

      <Modal
        className="gis_popups customerService"
        isOpen={customerService}
        onRequestClose={() => customerService}
      >
        <span
          id="saveLoadBanner"
          className={
            messageSent ? "saveLoad_span toggle_display" : "saveLoad_span"
          }
        >
          Comment Submitted Successfully
        </span>

        <div class="popup_heading">
          <h3>Help</h3>
          <button
            id="popup_close"
            className="popup_close"
            onClick={() => {
              setCustomerService(false);
              setMessageSent(false);
            }}
          >
            X
          </button>
        </div>

        <div className="popup_body">
          {/* <div className="feedback_suggestion">
            <h4>Feedback/Suggestions</h4>
            <p>
              Is something not working quite as expected? Do you have a suggestion for us? Please click on the link below to send us an email:
            </p>
            <p>
              <a
                href="mailto:help@vektagroup.com?subject=Digital Service Feedback/Suggestion&body=Dear%20Vekta%20Group%20Digital%20Team,"
              >
                Send Feedback
              </a>
            </p>
          </div> */}

          <div style={{ marginBottom: "60px" }} className="feedback_suggestion">
            <h4 style={{ textDecoration: "underline" }}>
              Feedback/Suggestions
            </h4>
            <p>
              Is something not working quite as expected? Do you have a
              suggestion for us? Please send us some feedback:
            </p>

            <span className="p-float-label" style={{ margin: "16px 0" }}>
              <InputTextarea
                id="yourComment"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                rows={5}
                style={{ width: "100%" }}
              />
              <label htmlFor="yourComment">Your Comment</label>
            </span>

            <ReplayTour style={{ float: "right" }} onClick={handleAddComment}>
              {!messageLoading ? (
                "Send Comment"
              ) : (
                <BounceLoader color="#00A0C6" size={30} />
              )}
            </ReplayTour>
          </div>

          <div style={{ marginTop: "24px" }}>
            <h4 style={{ textDecoration: "underline" }}>Data Sources</h4>
            <p>
              Copyright and licensing information for the data layers used in
              Vekta Digital can be downloaded here:
            </p>
            <p>
              <a onClick={handleMetaDownload}>Download Metadata</a>
            </p>
          </div>

          <div style={{ marginTop: "24px" }}>
            <h4 style={{ textDecoration: "underline" }}>In Tool Guidance</h4>
            <p>Re-play tool guidance on how-to use the platform:</p>
            <div>
              <ReplayTour
                onClick={() => {
                  setCustomerService(false);
                  setRunJoyrideSteps(steps);
                  setRunJoyride(true);
                }}
              >
                Platform Guidance
                <img
                  style={{ width: "25px", marginLeft: "8px" }}
                  src={dot + "./svg/help.svg"}
                />
              </ReplayTour>
              <ReplayTour
                onClick={() => {
                  setCustomerService(false);
                  setRunJoyrideSteps(stepsMap);
                  setRunJoyride(true);
                }}
              >
                Draw Tool Guidance
                <img
                  style={{ width: "25px", marginLeft: "8px" }}
                  src={dot + "./svg/help.svg"}
                />
              </ReplayTour>
            </div>
          </div>
          {/* <div className="user_guide">
            <h4>User Manual</h4>
            <p>
              Not sure what a widget does or how a feature works? Here is our
              simple User Manual:
            </p>
            <p>
              <a href="./data/Vekta_GIS_User_Manual_FREE.pdf" download
              >Link to user guide</a
              >
            </p>
          </div> */}
        </div>

        <div className="popup_footer">
          <footer>
            <button onClick={() => setCustomerService(false)}>Close</button>
          </footer>
        </div>
      </Modal>
    </div>
  );
}

export default Sidebar;
