/**
 * 
 * This code is written, owned and maintained by 
 * Vekta Group Energy Division.
 * 
 * © 2023, Vekta Group Energy Division.
 * 
 */

import React, { useState } from 'react';
import styled from "styled-components";
import IconButton from '@mui/material/IconButton';
import WindPowerIcon from '@mui/icons-material/WindPower';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDrawPolygon as DrawPolygonIcon,
  faMousePointer as SelectIcon,
  faMapMarkerAlt as LocationIcon,
  faTrash as DeleteIcon,  // Import the trash (delete) icon
  faAngleDoubleUp as MinimiseIcon,
  faRuler as Measure,
  faCircleXmark as ClearMeasures
} from '@fortawesome/free-solid-svg-icons';

import { faSquare as RectangleIcon } from '@fortawesome/free-regular-svg-icons';


import {
  Adjust as PointIcon,
  Grain as MultiSelectIcon,
  Timeline as LineIcon,
  ControlCamera as MoveIcon,
  OpenWith as MultiMoveIcon,
  Draw as DrawIcon
} from '@mui/icons-material';
import { Divider } from '@mui/material';
import { clearMeasurements } from './drawFunctions';
import { SecButton } from '../../styles/styles';

const ToolBox = styled.div`
  position: fixed;
  top:50%;
  right: 10px;
  transform: translate(0,-50%);
  border-radius:5px;
  border:1px solid lightgray;
  /* width:47px; */
  background-color: white;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1000;
`;

const SubToolBox = styled.div`
  position: fixed;
  top: ${props => props.pos ? ((props.pos.y / 10 + props.pos.height / 2) + "%") : "0px"};
  right: 47px;
  transform: translate(0,-50%);
  border-radius: 8px 0 0 8px;
  border: 1px solid lightgray;
  border-right: none;
  background-color: white;
  padding: 8px;
  z-index: 1000;
  width: 160px;
  display: flex;
  flex-direction: column;
`;

const StyledIconButton = styled(IconButton)`
  /* width:35px;
  height:35px; */
  background-color: ${props => props.active ? '#e0e0e0' : 'transparent'} !important;
  transition: background-color 0.2s ease, transform 0.2s ease;

  &:hover {
    background-color: #f5f5f5 !important;
    transform: scale(1.1);
  }
`;

function DrawControlPanel({ setMode, mode, configRef }) {
  const [minimised, setMinimised] = useState(false)

  const handleMinimise = () => {
    setMode("minimise")
    setMinimised(true)
  }

  const handleModeChange = (selection) => {
    // const measure = document.getElementById("polyline-measure-control")

    // if (measure && measure.classList.contains("polyline-measure-controlOnBgColor")) {
    //   measure.click()
    // }
    console.log(document.getElementById("draw_site").getBoundingClientRect());
    setMode(selection)
  }

  return (
    <ToolBox className='drawControls'>
      {!minimised ? (
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <StyledIconButton className='draw_select' active={mode === "select"} onClick={() => handleModeChange("select")} title="Select">
            <FontAwesomeIcon icon={SelectIcon} />
            {/* </StyledIconButton>
            <StyledIconButton active={mode === "multiSelect"} onClick={() => setMode("multiSelect")} title="Multi Select">
            <MultiSelectIcon /> */}
          </StyledIconButton>
          <StyledIconButton className='draw_move' active={mode === "move"} onClick={() => handleModeChange("move")} title="Move">
            <MoveIcon />
          </StyledIconButton>
          <Divider />
          {/* <StyledIconButton active={mode === "multiMove"} onClick={() => setMode("multiMove")} title="Multi Move">
            <MultiMoveIcon />
          </StyledIconButton> */}
          <StyledIconButton className='draw_point' active={mode === "point" || mode === "snapPoint"} onClick={() => handleModeChange("point")} title="Draw Point">
            <PointIcon />
          </StyledIconButton>
          <StyledIconButton className='draw_line' active={mode === "line" || mode === "snapLine"} onClick={() => handleModeChange("line")} title="Draw Line">
            <LineIcon />
          </StyledIconButton>
          <StyledIconButton className='draw_rectangle' active={mode === "rectangle"} onClick={() => handleModeChange("rectangle")} title="Draw Rectangle">
            <FontAwesomeIcon icon={RectangleIcon} />
          </StyledIconButton>
          <StyledIconButton className='draw_polygon' active={mode === "polygon" || mode === "snapPolygon"} onClick={() => handleModeChange("polygon")} title="Draw Polygon">
            <FontAwesomeIcon icon={DrawPolygonIcon} />
          </StyledIconButton>
          <Divider />

          <StyledIconButton className='draw_site' active={mode === "siteBuilder" || mode === "siteBuilder_cables" || mode === "siteBuilder_osp"} onClick={() => handleModeChange("siteBuilder")} title="Site Builder">
            <WindPowerIcon id={"draw_site"} fontSize="medium" />
          </StyledIconButton>
          {(mode === "siteBuilder" || mode === "siteBuilder_cables" || mode === "siteBuilder_osp") &&
            <SubToolBox pos={document.getElementById("draw_site").getBoundingClientRect()}>
              <SecButton style={{ width: "100%" }} active={mode === "siteBuilder"} onClick={() => handleModeChange("siteBuilder")} title="Build a Site">
                Build Site
              </SecButton>
              <SecButton style={{ width: "100%" }} active={mode === "siteBuilder_osp"} onClick={() => handleModeChange("siteBuilder_osp")} title="OSP Placement">
                Place OSP
              </SecButton>
              <SecButton style={{ width: "100%" }} active={mode === "siteBuilder_cables"} onClick={() => handleModeChange("siteBuilder_cables")} title="Cable Draw">
                Draw Cables
              </SecButton>
            </SubToolBox>
          }

          <StyledIconButton className='draw_location' active={mode === "locationMarker"} onClick={() => handleModeChange("locationMarker")} title="Location Marker">
            <FontAwesomeIcon icon={LocationIcon} />
          </StyledIconButton>
          <Divider />
          <StyledIconButton className='draw_delete' active={mode === "delete"} onClick={() => handleModeChange("delete")} title="Delete Layer">
            <FontAwesomeIcon icon={DeleteIcon} />
          </StyledIconButton>

          <StyledIconButton active={mode === "minimise"} onClick={handleMinimise} title="Minimise Tools">
            <FontAwesomeIcon icon={MinimiseIcon} />
          </StyledIconButton>
        </div>
      ) : (
        <StyledIconButton active={mode === "maximise"} onClick={() => setMinimised(false)} title="Show Draw Tools">
          <DrawIcon />
        </StyledIconButton>
      )}
    </ToolBox>
  );
}

export default DrawControlPanel;