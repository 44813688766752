/**
 * 
 * This code is written, owned and maintained by 
 * Vekta Group Energy Division.
 * 
 * © 2023, Vekta Group Energy Division.
 * 
 */

import base64 from 'react-native-base64';

/**
*
* Fetch request to GeoServer for the current layers saved.
*
**/
const getLayerNames = () => {
    // const fs = require('fs');
    return new Promise((resolve, reject) => {
        fetch(`https://${process.env.REACT_APP_GEO_SERVER_REST_URL}layers.json`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Basic ${base64.encode(`${process.env.REACT_APP_GEO_SERVER_USERNAME}:${process.env.REACT_APP_GEO_SERVER_PASSWORD}`)}`,
            },
        })
            .then((response) => {
                if (response.status === 401) {
                    resolve(0);
                } else if (response.status === 200) {
                    response.json().then((data) => {
                        data.layers.layer.push({ name: "Bathymetry:GLOBAL_Bathymetry" })
                        console.log(JSON.stringify(data.layers.layer));

                        // fs.writeFile("data.json", data.layers.layer, (error) => {
                        //     // throwing the error
                        //     // in case of a writing problem
                        //     if (error) {
                        //         // logging the error
                        //         console.error(error);

                        //         throw error;
                        //     }

                        //     console.log("data.json written correctly");
                        // });
                        resolve(data.layers);
                    });
                }
                return response;
            })
            .catch((err) => {
                console.log(err);
            });
    });
};

/**
 * 
 * GeoServer REST API calls to get the feature data
 * 
 * @param {Selected layer id} layer 
 * @returns The feature data
 */
const getLayerBounds = (layer) => {
    return new Promise((resolve, reject) => {
        fetch(`https://${process.env.REACT_APP_GEO_SERVER_REST_URL}workspaces/` + layer.split(":")[0] + "/datastores", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Basic ${base64.encode(`${process.env.REACT_APP_GEO_SERVER_USERNAME}:${process.env.REACT_APP_GEO_SERVER_PASSWORD}`)}`,
            }
        })
            .then((response) => {
                if (response.status === 401) {
                    resolve(0);
                } else if (response.status === 200) {
                    response.json().then((data) => {
                        var dataStore = data.dataStores.dataStore[0]
                        if (data.dataStores.dataStore.length > 1) {
                            dataStore = data.dataStores.dataStore[1]
                        }
                        fetch(`https://${process.env.REACT_APP_GEO_SERVER_REST_URL}workspaces/` + layer.split(":")[0] + "/datastores/" + dataStore.name + "/featuretypes/" + layer.split(":")[1], {
                            method: "GET",
                            headers: {
                                "Accept": "application/json",
                                "Content-Type": "application/json",
                                "Authorization": `Basic ${base64.encode(`${process.env.REACT_APP_GEO_SERVER_USERNAME}:${process.env.REACT_APP_GEO_SERVER_PASSWORD}`)}`,
                            }
                        })
                            .then((response) => {
                                if (response.status === 401) {
                                    resolve(0);
                                } else if (response.status === 200) {
                                    response.json().then((data) => {
                                        resolve(data);
                                    });
                                }
                                return response;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    });
}

export {
    getLayerNames,
    getLayerBounds
}