import React from 'react'
import styled from "styled-components";

const Container = styled.div`
    position: absolute;
    inset: 40px 40px 40px 50%;
    background: white;
    padding: 24px;
    transition: ease-in 2s;
    border-radius: 8px;
    box-shadow: 0px 0px 20px #a4a4a4;
`;

function Stripe({ setSidePanel }) {
    return (
        <Container>
            <div className='sidePanel_heading'>
                <h3>Payment Method</h3>
                <button onClick={() => setSidePanel({ open: false, type: null })}>X</button>
            </div>

            <div className='sidePanel_body'>

            </div>
        </Container>
    )
}

export default Stripe