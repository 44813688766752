/**
 * 
 * This code is written, owned and maintained by 
 * Vekta Group Energy Division.
 * 
 * © 2023, Vekta Group Energy Division.
 * 
 */

import $ from 'jquery';
import L from "leaflet";
// import 'leaflet-wms-header';
import "leaflet-measure-path"
import { grid } from "../../data/GridERA5";

import pointIconUrl from "./icons/point.svg";
import snapPointIconUrl from "./icons/snapPoint.svg";
import polygonPointUrl from "./icons/polygonPoint.svg"
import snapPolygonPointUrl from "./icons/snapPolygonPoint.svg"
import snapLinePointUrl from "./icons/snapLinePoint.svg"
import linePointUrl from "./icons/linePoint.svg"
import locationMarkerUrl from "./icons/locationMarker.svg"

import { Icon } from "leaflet";

import * as WMS from "leaflet.wms";
import { getWindRoseAt, getWindSpeedAt } from './apiCalls';

/**
 * 
 * Zoom to Selected layer 
 * 
 * @param {selected layer to zoom to} layer 
 * @param {ref to map to enable flyToBounds} map 
 */
export const zoomToLayer = (layer, map) => {
  var box;
  if (layer.length > 0) {
    box = {
      maxx: layer[0],
      maxy: layer[1],
      minx: layer[2],
      miny: layer[3],
    }
  } else {
    box = layer.featureType.latLonBoundingBox;
  }

  map.flyToBounds([
    [box.miny, box.minx],
    [box.maxy, box.maxx]
  ])
}

/**
 * 
 * Check selected layer(s) to show them on the map
 * 
 */
export const showSelected = () => {
  const layers = $(".projectJSTree").jstree(true).get_selected();

  $(".projectJSTree").jstree(true).check_node(layers);
}

/**
 * 
 * Uncheck selected layer(s) to hide them on the map
 * 
 */
export const hideSelected = () => {
  if ($(".projectJSTree").jstree(true)) {
    const layers = $(".projectJSTree").jstree(true).get_selected();

    $(".projectJSTree").jstree(true).uncheck_node(layers);
  }
}

/**
 * 
 * Creation of the ERA5 grid on a single canvas
 * 
 * @param {Leaflet Map} map 
 */
export const createERA5 = (map, platformData, setPlatformData, setLoadingStatus) => {
  map.eachLayer((layer) => {
    if (layer.options.pane === "era5-grid") {
      map.removeLayer(layer);
    }
  });

  const myRenderer = L.canvas({ pane: "era5-grid" });

  for (let i = 0; i < grid.length; i++) {
    L.circle(
      [grid[i].lat, grid[i].long], 1000, {
      stroke: "#007592",
      color: "#009ec6",
      renderer: myRenderer
    }
    ).bindTooltip("Latitude: " + grid[i].lat + "<br>Longitude: " + grid[i].long, { className: 'latlongtooltip' }).openTooltip().addTo(map).on("click", circleClick);
  }

  function circleClick(e) {
    setLoadingStatus({ loading: true, loadingMsg: "Processing ERA5 Data . . ." })

    map.eachLayer((layer) => {
      if (layer.options.pane === "era5-point") {
        map.removeLayer(layer);
      }
    });

    getWindRoseAt(e.target._latlng.lat, e.target._latlng.lng, platformData, setPlatformData)
    getWindSpeedAt(e.target._latlng.lat, e.target._latlng.lng, platformData, setPlatformData)
  }
}

/**
 * 
 *
 * 
 */
export const addUploadedLayer = (uploadedData, setUploadedData, map, data) => {
  const shownData = [];

  // Clear existing layers from the specified pane
  map.eachLayer((layer) => {
    if ((layer.options.pane === "uploaded-layer" && layer.feature) || (layer.options.pane === "uploaded-layer" && layer.options.radius)) {
      map.removeLayer(layer);
    }
  });

  uploadedData.bindTooltip(function (layer) {
    var popupInfo = "";

    Object.keys(layer.feature.properties).map((propVal) => {
      popupInfo = popupInfo + "<strong>" + propVal + "</strong>" + ": " + layer.feature.properties[propVal] + "<br>"
    })
    return popupInfo;
  });

  for (let i = 0; i < data.data.length; i++) {
    if (data.shown[i]) {
      shownData.push(data.data[i])
    }
  }

  uploadedData.addData(shownData).addTo(map);

  setUploadedData(uploadedData);
}

export const addQueryLayer = (layer, pane, zindex, map, filter, opacity) => {
  var options = {
    format: "image/png",
    transparent: true,
    info_format: "text/html",
    pane: pane,
    tiled: true,
    //className: "layer_query",
    className: layer[0].split(":")[1] + "_query",
    zIndex: zindex,
    checkempty: true,
    minZoom: 3,
    maxZoom: 18,
    noWrap: true,
    opacity: opacity / 100,
  }

  if (filter) {
    options = {
      format: "image/png",
      transparent: true,
      info_format: "text/html",
      pane: pane,
      tiled: true,
      cql_filter: filter,
      //className: "layer_query",
      className: layer[0].split(":")[1] + "_query",
      zIndex: zindex,
      minZoom: 3,
      maxZoom: 18,
      noWrap: true
    }
  }

  // let url = process.env.REACT_APP_GEO_SERVER_URL + layer[0].split(":")[0] + "/wms?"
  // if (geowebcache.includes(layer[0])) url = process.env.REACT_APP_GEO_SERVER_URL_GEOWEBCACHE
  const url = `https://${process.env.REACT_APP_GEO_SERVER_URL}` + layer[0].split(":")[0] + "/wms?"

  const source = WMS.source(
    url,
    options,
  );

  source.showFeatureInfo = (latlng, info) => {
    const parser = new DOMParser();
    const html = parser.parseFromString(info, 'text/html');

    if (!map || html.body.innerHTML.length === 7) {
      return;
    }
    map.openPopup(info, latlng);
  }

  source.onRemove = () => {
    if (!this?._map) {
      return
    } else {
      this._source.removeSubLayer(this._name);
    }
  }

  for (let name of layer) {
    console.log(name);
    source.getLayer(name).addTo(map)
  }

  return source;
}

export const removeQueryLayer = (layerQuery) => {
  layerQuery.layerSource.removeSubLayer(layerQuery.layerName)
}

// export const addWMSLayer = (map, val, zIndex) => {
//     var wmsLayer = L.TileLayer.wmsHeader(
//         `https://${process.env.REACT_APP_GEO_SERVER_URL}wms?`,
//         {
//             layers: val,
//             format: 'image/png',
//             transparent: true,
//             pane: "active-wms",
//             minZoom: 3,
//             detectRetina: true,
//             maxZoom: 18,
//             noWrap: true,
//             className: val.split(":")[1] + "_active",
//             zIndex: zIndex
//         },
//         [
//             { header: 'kdsjE7hfy', value: 'koniwatson' }
//             // { header: 'Authorization', value: `Basic ${base64.encode(`${process.env.REACT_APP_GEO_SERVER_USERNAME}:${process.env.REACT_APP_GEO_SERVER_PASSWORD}`)}` }
//         ],
//         null
//     ).addTo(map);
// }

export const formatCqlFilter = (filter) => {
  var string = ""

  Object.keys(filter).map((key) => {
    if (filter[key].value) {
      if (string !== "") {
        string = string + " AND " + key + "='" + filter[key].value + "'"
      } else {
        string = string + key + "='" + filter[key].value + "'"
      }
    }
  })

  if (string === "") {
    return null;
  } else {
    return string
  }
  //"port_name='BERWICK-UPON-TWEED'"
}

/**
 * 
 * Add third party measure tool - PolylineMeasure
 * 
 * @param {Leaflet Map} map 
 */
export const addMeasureTool = (map) => {
  const options = {
    position: 'topright',
    unit: 'kilometres',
    useSubunits: true,
    clearMeasurementsOnStop: false,
    showBearings: true,
    bearingTextIn: 'In',
    bearingTextOut: 'Out',
    tooltipTextFinish: 'Click to <b>Finish Line</b><br>',
    tooltipTextDelete: 'Press SHIFT-key and Click to <b>Delete Point</b>',
    tooltipTextMove: 'Click and drag to <b>move point</b><br>',
    tooltipTextResume: '<br>Press CTRL-key and click to <b>resume line</b>',
    tooltipTextAdd: 'Press CTRL-key and click to <b>add point</b>',
    measureControlTitleOn: 'Measure Tool On',
    measureControlTitleOff: 'Measure Tool Off', // Title for the Measure Control going to be switched off
    measureControlLabel: '<svg xmlns="http://www.w3.org/2000/svg" height="3em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M177.9 494.1c-18.7 18.7-49.1 18.7-67.9 0L17.9 401.9c-18.7-18.7-18.7-49.1 0-67.9l50.7-50.7 48 48c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-48-48 41.4-41.4 48 48c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-48-48 41.4-41.4 48 48c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-48-48 41.4-41.4 48 48c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-48-48 50.7-50.7c18.7-18.7 49.1-18.7 67.9 0l92.1 92.1c18.7 18.7 18.7 49.1 0 67.9L177.9 494.1z"/></svg>', // Label of the Measure Control (Unicode symbols are possible)
    measureControlClasses: ['measureTool'],      // Classes to apply to the Measure Control
    showClearControl: true,        // Show a control to clear all the measurements
    clearControlTitle: 'Clear Measurements', // Title text to show on the Clear Control
    clearControlLabel: '<svg xmlns="http://www.w3.org/2000/svg" height="3em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"/></svg>',    // Label of the Clear Control (Unicode symbols are possible)
    clearControlClasses: ['measureTool'],        // Classes to apply to Clear Control
    showUnitControl: true,         // Show a control to change the units of measurements
    unitControlUnits: ["kilometres", "landmiles", "nauticalmiles"],
    // measurement units being cycled through by using the Unit Control
    unitControlTitle: {             // Title texts to show on the Unit Control
      text: 'Change Units',
      metres: 'kilometres',
      kilometres: 'kilometres',
      landmiles: 'land miles',
      nauticalmiles: 'nautical miles'
    },
    unitControlLabel: {             // Unit symbols to show in the Unit Control and measurement labels
      metres: 'm',
      kilometres: 'km',
      feet: 'ft',
      landmiles: 'mi',
      nauticalmiles: 'nm'
    },
    unitControlClasses: ["measureTool unitControlId"],         // Classes to apply to the Unit Control
    tempLine: {                     // Styling settings for the temporary dashed line
      color: '#007592',              // Dashed line color
      weight: 2                   // Dashed line weight
    },
    fixedLine: {                    // Styling for the solid line
      color: '#006',              // Solid line color
      weight: 2,
      display: 'none'                   // Solid line weight
    },
    arrow: {                        // Styling of the midway arrow
      color: '#000',              // Color of the arrow
    },
    startCircle: {                  // Style settings for circle marker indicating the starting point of the polyline
      color: '#000',              // Color of the border of the circle
      weight: 1,                  // Weight of the circle
      fillColor: '#0f0',          // Fill color of the circle
      fillOpacity: 1,             // Fill opacity of the circle
      radius: 3                   // Radius of the circle
    },
    intermedCircle: {               // Style settings for all circle markers between startCircle and endCircle
      color: '#000',              // Color of the border of the circle
      weight: 1,                  // Weight of the circle
      fillColor: '#ff0',          // Fill color of the circle
      fillOpacity: 1,             // Fill opacity of the circle
      radius: 3                   // Radius of the circle
    },
    currentCircle: {                // Style settings for circle marker indicating the latest point of the polyline during drawing a line
      color: '#000',              // Color of the border of the circle
      weight: 1,                  // Weight of the circle
      fillColor: '#f0f',          // Fill color of the circle
      fillOpacity: 1,             // Fill opacity of the circle
      radius: 6                   // Radius of the circle
    },
    endCircle: {                    // Style settings for circle marker indicating the last point of the polyline
      color: '#000',              // Color of the border of the circle
      weight: 1,                  // Weight of the circle
      fillColor: '#f00',          // Fill color of the circle
      fillOpacity: 1,             // Fill opacity of the circle
      radius: 3                   // Radius of the circle
    },
  };

  L.control.polylineMeasure(options).addTo(map);
}

export const addLegendViewer = (map) => {
  const legend = L.control({ position: "bottomright" });

  legend.onAdd = () => {
    const container = L.DomUtil.create("div", "info leafet_legendContaier");
    const legends = L.DomUtil.create("div", "info leafet_legends", container);
    const close = L.DomUtil.create('button', 'legend_close leaflet-control-button', container)
    const minimise = L.DomUtil.create('button', 'legend_minimise leaflet-control-button', container)

    container.style.overflowY = "scroll"
    container.style.overflowX = "hidden"
    container.style.height = "300px"
    container.style.width = "200px"
    container.title = "Layer Legends"

    legends.id = "activeLayers_legend"

    close.innerHTML = "X"

    minimise.style.display = "none"
    minimise.innerHTML = "<span class='glyphicon glyphicon-list'></span>"

    L.DomEvent.disableScrollPropagation(container)
    L.DomEvent.disableScrollPropagation(legends)

    L.DomEvent.disableClickPropagation(close);
    L.DomEvent.on(close, 'click', function () {
      minimise.style.display = "block"

      close.style.display = "none"

      legends.style.display = "none"

      container.style.height = "48px"
      container.style.width = "48px"

      container.style.overflowY = "hidden"
      container.style.overflowX = "hidden"
    });

    L.DomEvent.disableClickPropagation(minimise);
    L.DomEvent.on(minimise, 'click', function () {
      minimise.style.display = "none"

      close.style.display = "block"

      legends.style.display = "flex"

      container.style.height = "300px"
      container.style.width = "200px"
      container.style.overflowY = "scroll"
      container.style.overflowX = "hidden"
    });

    close.click();

    return container;
  };

  legend.addTo(map);
}

export const loadIcons = () => {
  const icon_size_k = 1
  const k = icon_size_k

  const pointIcon = new Icon({
    iconUrl: pointIconUrl,
    iconSize: [10 * k, 10 * k],
  });

  const snapPointIcon = new Icon({
    iconUrl: snapPointIconUrl,
    iconSize: [16 * k, 16 * k],
  });

  const linePointIcon = new Icon({
    iconUrl: linePointUrl,
    iconSize: [10 * k, 10 * k],
  });
  const snapLinePointIcon = new Icon({
    iconUrl: snapLinePointUrl,
    iconSize: [16 * k, 16 * k],
  });

  const polygonPointIcon = new Icon({
    iconUrl: polygonPointUrl,
    iconSize: [10 * k, 10 * k]
  })

  const snapPolygonPointIcon = new Icon({
    iconUrl: snapPolygonPointUrl,
    iconSize: [16 * k, 16 * k]
  })

  let pad = 10
  const locationMarkerIcon = new L.Icon({
    iconUrl: locationMarkerUrl,
    iconSize: [24 * k, 24 * k],
    iconAnchor: [12 * k, 24 * k],
    interactive: true,
    iconHitArea: new L.Bounds(
      L.point([-pad * k, -pad * k]),
      L.point([24 * k + pad * k, 24 * k + pad * k])
    )
  });


  const iconTypes = {
    point: pointIcon,
    snapPoint: snapPointIcon,
    linePoint: linePointIcon,
    snapLinePoint: snapLinePointIcon,
    polygonPoint: polygonPointIcon,
    snapPolygonPoint: snapPolygonPointIcon,
    locationMarker: locationMarkerIcon,

  };
  return iconTypes
}